<ion-header>
  <ion-toolbar color="header">
    <ion-buttons slot="start">
      <ion-menu-button
        *ngIf="!localStorage.getIsInAppBrowser() && smallScreen && userIsLoggedIn"
        menu="appMenu"
        autoHide="false"
        id="appmenuButton"
      ></ion-menu-button>
      <ion-button
        *ngIf="!localStorage.hideHeaderBackButton() && back"
        id="backButton"
        (click)="goBack()"
      >
        <ion-icon name="arrow-back" color="font-header"></ion-icon>
      </ion-button>
      <ion-button
        *ngIf="save"
        [disabled]="!answersChanged && !userIsLoggedIn"
        id="saveLessonButton"
        (click)="saveQuestionnaire()"
      >
        <ion-icon name="save" color="font-header"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="ion-text-center" [hidden]="isMobile" color="font-header">
      <h1>
        {{ title }}
      </h1>
    </ion-title>

    <div *ngIf="!localStorage.getIsInAppBrowser() && userIsLoggedIn" slot="end" class="activityDiv">
      <button
        *ngIf="parserService.announcements.length > 0"
        mat-button
        id="showHideAnnouncements"
        (click)="showHideAnnouncements()"
      >
        <fa-icon [icon]="faBullhorn"></fa-icon>
      </button>
      <button
        mat-button
        [matMenuTriggerFor]="menuRef"
        id="showActivities"
        (menuOpened)="menuOpened()"
      >
        <fa-icon [icon]="faHistory"></fa-icon>
      </button>
      <mat-menu #menuRef="matMenu">
        <h4 style="text-align: center">
          {{ "HOME.YOUR_ACTIVITYLOG" | translate }}
        </h4>
        <div *ngFor="let activity of loadedActivities" mat-menu-item>
          {{ activity.text }}
          <div class="datetext">
            {{
              helperService.formatDate(
                activity.created_at,
                localStorage.getAppLanguage()
              )
            }}
          </div>
        </div>
        <ion-content>
          <ion-infinite-scroll threshold="100px" id="scrollActivities">
            <ion-infinite-scroll-content
              loading-spinner="bubbles"
              loading-text="Loading more data..."
            >
            </ion-infinite-scroll-content>
          </ion-infinite-scroll>
        </ion-content>
      </mat-menu>
    </div>

    <div *ngIf="!localStorage.getIsInAppBrowser()" slot="end" class="languageDiv">
      <ion-select
        [value]="language"
        class="header"
        interface="popover"
        placeholder="GENERAL.SELECT_LANGUAGE"
        id="inputSelectedLanguage"
        (ionChange)="changeLanguage($event.detail.value)"
      >
        <ion-select-option value="de" id="inputSelectedLanguagede">
          {{ "GENERAL.GERMAN" | translate }}
        </ion-select-option>
        <ion-select-option value="en" id="inputSelectedLanguageen">
          {{ "GENERAL.ENGLISH" | translate }}
        </ion-select-option>
        <ion-select-option value="fr" id="inputSelectedLanguagefr">
          {{ "GENERAL.FRENCH" | translate }}
        </ion-select-option>
        <ion-select-option value="es" id="inputSelectedLanguagees">
          {{ "GENERAL.SPANISH" | translate }}
        </ion-select-option>
        <ion-select-option value="ca" id="inputSelectedLanguageca">
          {{ "GENERAL.CATALAN" | translate }}
        </ion-select-option>
        <ion-select-option value="ro" id="inputSelectedLanguagero">
          {{ "GENERAL.ROMANIAN" | translate }}
        </ion-select-option>
      </ion-select>
    </div>

    <ion-buttons *ngIf="localStorage.getIsInAppBrowser() && userIsLoggedIn" slot="end">
      <ion-button
        slot="end"
        id="showInvitationsButton"
        (click)="routeToInvitations()"
      >
        <ion-icon name="paper-plane" color="font-header"></ion-icon>
      </ion-button>
    </ion-buttons>

    <ion-buttons *ngIf="!localStorage.getIsInAppBrowser() && userIsLoggedIn" slot="end">
      <ion-button
        class="logoutButton"
        slot="end"
        id="logoutButton"
        (click)="logout()"
      >
        <ion-icon name="power" color="font-header"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>

  <div
    #announcementDiv
    *ngIf="
      parserService.showAnnouncements &&
      parserService.announcements.length > 0 &&
      userIsLoggedIn
    "
    class="announcement"
    [ngClass]="parserService.announcements[parserService.selectedAnnouncement].type"
  >
    <ion-grid>
      <ion-row>
        <ion-col size="10" offset="1" class="announcement-title-container">
          <ion-label class="announcement-title">
            {{
              parserService.announcements[parserService.selectedAnnouncement].title
            }}
          </ion-label>
        </ion-col>
        <ion-col size="1" class="close-icon-container">
          <ion-icon
            name="close"
            id="hideAnnouncementsButton"
            class="close-icon"
            (click)="showHideAnnouncements()"
          ></ion-icon>
        </ion-col>
      </ion-row>

      <ion-row class="announcement-message-container">
        <ion-col size="12">
          <p class="announcement-message" [class.fontSizeMobile]="isMobile">
            {{
              parserService.announcements[parserService.selectedAnnouncement].message
            }}
          </p>
        </ion-col>
      </ion-row>

      <ion-row class="announcement-nav">
        <ion-col size="12" class="nav-container">
          <ion-icon
            name="arrow-back"
            class="nav-icon"
            (click)="previousAnnouncement()"
            [ngClass]="{ 'disabled': parserService.selectedAnnouncement === 0 }"
            [attr.disabled]="parserService.selectedAnnouncement === 0 ? true : null"
          ></ion-icon>
          <span class="nav-text">
            {{ parserService.selectedAnnouncement + 1 }}
            {{ "GENERAL.OF" | translate }}
            {{ parserService.announcements.length }}
          </span>
          <ion-icon
            name="arrow-forward"
            class="nav-icon"
            (click)="nextAnnouncement()"
            [ngClass]="{ 'disabled': parserService.selectedAnnouncement === parserService.announcements.length - 1 }"
            [attr.disabled]="parserService.selectedAnnouncement === parserService.announcements.length - 1 ? true : null"
          ></ion-icon>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</ion-header>

<div class="dialog">
  <ion-buttons style="justify-content: flex-end; margin-bottom: 20px">
    <ion-button id="closeButton" (click)="onCloseDialog()">
      <ion-icon name="close-circle-outline" size="large"></ion-icon>
    </ion-button>
  </ion-buttons>
  @if (unlockType === 'manual') {
    <div class="information">
      <h3>
        {{ getText() }}
      </h3>

      @if (offers.length === 0) {
        <div class="information">
          @for (lesson of alreadyUnlockedDetails; track $index) {
            <ul class="alreadyUnlockedLessons">
              <li>{{ lesson.title }}</li>
            </ul>
          }
          <p>
            {{ "LESSON.ALREADY_UNLOCKED_BONUSLESSONS_INFO" | translate }}
          </p>
        </div>
      } @else {
        <h4>{{ getUnlockOneMultipleText() }}</h4>
      }
    </div>
    @if (offers.length > 0) {
      <div
        id="unlockQuestionnairesList"
        class="questionnaires"
        [class.topBorder]="showTopBorder && !showBottomBorder"
        [class.bottomBorder]="showBottomBorder && !showTopBorder"
        [class.bothBorders]="showTopBorder && showBottomBorder"
      >
        <ion-grid>
          @if (!unlockMultiple) {
            <ion-radio-group [(ngModel)]="selectedLesson">
              @for (offer of offers; track $index; let i = $index) {
                <div>
                  <ion-row
                    class="ion-align-items-center"
                    style="text-align-last: end"
                  >
                    <ion-col size="1" style="text-align-last: end">
                      <ion-radio
                        [value]="i"
                        [attr.id]="'inputSelectLesson' + i"
                      ></ion-radio>
                    </ion-col>
                    <ion-col size="11">
                      <ion-card
                        [class.choosen]="selectedLesson === i"
                        [attr.id]="'lessonCard' + i"
                        (click)="selectedLesson = selectedLesson === i ? -1 : i"
                      >
                        <ng-container>
                          <ion-grid>
                            <ion-row>
                              <ion-card-header>
                                <ion-card-title>
                                  {{ offer.unlock.title }}
                                </ion-card-title>
                              </ion-card-header>
                            </ion-row>
                            @if (offer.unlock.description) {
                              <ion-row>
                                <ion-card-content>
                                  {{ offer.unlock.description }}
                                </ion-card-content>
                              </ion-row>
                            }
                          </ion-grid>
                        </ng-container>
                      </ion-card>
                    </ion-col>
                  </ion-row>
                  @if (
                    !unlockMultiple &&
                    offers.length > 0 &&
                    selectedLesson != -1 &&
                    selectedLesson != undefined &&
                    selectedLesson === i
                  ) {
                    <ion-row
                      class="ion-align-items-center resetButton"
                      style="text-align-last: end"
                    >
                      <ion-button
                        id="removeSelectionButton"
                        color="primary"
                        fill="solid"
                        (click)="removeSelection()"
                      >
                        {{ "LESSON.RESET" | translate }}
                      </ion-button>
                    </ion-row>
                  }
                </div>
              }
              <!-- <ion-row class="ion-align-items-center" style="text-align-last: end">
              <ion-col size="1" style="text-align-last: end">
                <ion-radio [value]="-2"></ion-radio>
              </ion-col>
              <ion-col size="11">
                <ion-card [class.choosen]="selectedLesson === -2" (click)="selectedLesson = (selectedLesson === -2) ? -1 : -2">
                  <ng-container>
                    <ion-grid>
                      <ion-row>
                        <ion-card-header>
                          <ion-card-title>
                            {{ 'LESSON.NONE' | translate }}
                          </ion-card-title>
                        </ion-card-header>
                      </ion-row>
                    </ion-grid>
                  </ng-container>
                </ion-card>
              </ion-col>
            </ion-row> -->
            </ion-radio-group>
          }
          @if (unlockMultiple) {
            <div>
              @for (offer of offers; track $index; let i = $index) {
                <ion-row class="ion-align-items-center" style="text-align-last: end">
                  <ion-col size="1" style="text-align-last: end">
                    <ion-checkbox
                      [(ngModel)]="choosenOffers[i]"
                      [attr.id]="'inputSelectLesson' + i"
                    ></ion-checkbox>
                  </ion-col>
                  <ion-col size="11">
                    <ion-card
                      [class.choosen]="choosenOffers[i]"
                      [attr.id]="'lessonCard' + i"
                      (click)="choosenOffers[i] = !choosenOffers[i]"
                    >
                      <ng-container>
                        <ion-grid>
                          <ion-row>
                            <ion-card-header>
                              <ion-card-title>
                                {{ offer.unlock.title }}
                              </ion-card-title>
                            </ion-card-header>
                          </ion-row>
                          @if (offer.unlock.description) {
                            <ion-row>
                              <ion-card-content>
                                {{ offer.unlock.description }}
                              </ion-card-content>
                            </ion-row>
                          }
                        </ion-grid>
                      </ng-container>
                    </ion-card>
                  </ion-col>
                </ion-row>
              }
            </div>
          }
        </ion-grid>
      </div>
    }
    <div id="scrollUnlockLessons" class="scrollIndication">
      <ion-row style="text-align-last: end">
        <fa-icon [icon]="faAngleDoubleDown"></fa-icon>
      </ion-row>
    </div>
    @if (alreadyUnlockedDetails.length > 0 && offers.length > 0) {
      <div class="information">
        <h5>
          {{ "LESSON.ALREADY_UNLOCKED_BONUSLESSONS" | translate }}
        </h5>

        @for (lesson of alreadyUnlockedDetails; track $index) {
          <ul class="alreadyUnlockedLessons">
            <li>{{ lesson.title }}</li>
          </ul>
        }

        <p>
          {{ "LESSON.ALREADY_UNLOCKED_BONUSLESSONS_INFO" | translate }}
        </p>
      </div>
    }
    <div class="dialog-action">
      @if (offers.length > 0) {
        <ion-button
          id="unlockLessonsButton"
          class="dialog-action"
          color="primary"
          fill="solid"
          (click)="unlock()"
        >
          {{ getButtonText() }}
        </ion-button>
      } @else {
        <div style="text-align: center;">
          <ion-button
            id="goToLessonOverview"
            color="primary"
            fill="solid"
            (click)="closeNonManualDialog('LessonOverview')"
          >
            {{ "PAGES.LESSON_OVERVIEW" | translate }}
          </ion-button>
          @if (isDiary) {
            <ion-button
              id="goToDiaryOverview"
              color="primary"
              fill="solid"
              (click)="closeNonManualDialog('DiaryOverview')"
            >
              {{ "PAGES.DIARY" | translate }}
            </ion-button>
          }
        </div>
      }
    </div>
  } @else {
    <h3>
      {{ "LESSON.UNLOCKED_LESSONS_SUCCESSFULLY" | translate }}:
    </h3>
    @if (alreadyUnlockedDetails.length > 0) {
      <div class="information">
        <ion-list lines="full">
          @for (lesson of alreadyUnlockedDetails; track $index) {
            <ion-item button [detail]="true" (click)="openLesson(lesson)">
              <ion-label>{{ lesson.title }}</ion-label>
            </ion-item>
          }
        </ion-list>

        <p>{{ "LESSON.UNLOCKED_LESSONS_INFO" | translate }}</p>
        <div style="text-align: center;">
          <ion-button
            id="goToLessonOverview"
            color="primary"
            fill="solid"
            (click)="closeNonManualDialog('LessonOverview')"
          >
            {{ "PAGES.LESSON_OVERVIEW" | translate }}
          </ion-button>
          @if (isDiary) {
            <ion-button
              id="goToDiaryOverview"
              color="primary"
              fill="solid"
              (click)="closeNonManualDialog('DiaryOverview')"
            >
              {{ "PAGES.DIARY" | translate }}
            </ion-button>
          }
        </div>
      </div>
    } @else {
      <div style="color: red;">
        {{ "ERROR.ERROR_INVALID_UNLOCK_DATA" | translate }}
      </div>
    }
  }
</div>

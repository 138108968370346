import { SensoryInformation } from "./SensoryInformation";

export class Answersheet {
  id: number;
  lessonId: number;
  instanceID: number;
  diaryInstanceID: number;
  answers: Array<any>;
  locale: string;
  collected_at: number;
  created_at: number;
  updated_at: number;
  version: string;
  finished: number;
  sensor_data: SensoryInformation | null

  constructor(id: number, lessonId: number, instanceID: number, diaryInstanceID: number, answers: Array<any>, locale: string, collected_at: number, created_at: number, updated_at: number, version: string, finished: number, sensor_data: SensoryInformation = null) {
    this.id = id;
    this.lessonId = lessonId;
    this.instanceID = instanceID;
    this.diaryInstanceID = diaryInstanceID;
    this.answers = answers;
    this.locale = locale;
    this.collected_at = collected_at;
    this.created_at = created_at;
    this.updated_at = updated_at;
    this.version = version;
    this.finished = finished;
    this.sensor_data = sensor_data;
  }
}

import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  GuardResult,
  MaybeAsync,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { of } from "rxjs";
import { LocalStorageService } from "../providers/local-storage/local-storage.service";

@Injectable({
  providedIn: "root",
})
export class WebAppGuard {
  constructor(
    private router: Router,
    private localStorage: LocalStorageService,
  ) { }

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): MaybeAsync<GuardResult> {
    if (!this.localStorage.getIsInAppBrowser()) {
      return of(true);
    } else {
      this.router.navigateByUrl("/intervention-overview");
      return of(false);
    }
  }
}

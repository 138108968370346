import { Answersheet } from "./Answersheet";
import { Schedule } from "./Schedule";
import { Sensor } from "../components/diary-sensor-info/diary-sensor-info.component";

export class DiaryInstance{
  id: number;
  diaryID: number;
  title: string;
  description: string;
  picture: string;
  lessonOfDiary: number;
  studyId: number;
  interventionInstanceId: number;
  schedules: Schedule[];
  hasQuicksavedAnswers: boolean = false;
  answersheets: Answersheet[] = [];
  sensorData: Sensor[];

  constructor(
    id: number,
    diaryID: number,
    title: string,
    description: string,
    picture: string,
    lessonOfDiary: number,
    studyId: number,
    interventionInstanceId: number,
    schedules: Schedule[],
    sensorData: Sensor[],
  ) {
    this.id = id;
    this.diaryID = diaryID;
    this.title = title;
    this.description = description;
    this.picture = picture;
    this.lessonOfDiary = lessonOfDiary;
    this.studyId = studyId;
    this.interventionInstanceId = interventionInstanceId;
    this.schedules = schedules;
    this.sensorData = sensorData;
  }
}

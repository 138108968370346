import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from "@angular/core";
import { NavController, Platform } from "@ionic/angular";
import { HttpResponse } from "@angular/common/http";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { faBullhorn, faHistory } from "@fortawesome/free-solid-svg-icons";
import { map, catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { Activity } from "../../models/Activity";
import { AlertService } from "../../providers/alert/alert.service";
import { LocalStorageService } from "../../providers/local-storage/local-storage.service";
import { ParserService } from "../../providers/parser/parser.service";
import { HelperService } from "../../providers/helper/helper.service";
import { RequestProviderService } from "../../providers/request-provider/request-provider.service";
import { TokenInterceptorService } from "../../providers/token-interceptor/token-interceptor.service";
import { BACKEND_URL } from "../../../environments/environment";
import { RequestHelperService } from "src/app/providers/request-helper/request-helper.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, AfterViewChecked {
  @Input() title: string;
  @Input() back = false;
  @Input() save = false;
  @Input() answersChanged: boolean;
  @Input() confirmBack = false;
  @Input() icon: string;
  @Input() userIsLoggedIn: boolean = true;
  @Output("saveEmitter") saveEmitter: EventEmitter<Event> =
    new EventEmitter<Event>();
  @Output("backEmitter") backEmitter: EventEmitter<Event> =
    new EventEmitter<Event>();
  @Output("changeLanguageEmitter") changeLanguageEmitter: EventEmitter<Event> =
    new EventEmitter<Event>();
  @Output("logoutEmitter") logoutEmitter: EventEmitter<Event> =
    new EventEmitter<Event>();

  @ViewChild("announcementDiv") announcementDiv!: ElementRef;

  private language: string;
  public smallScreen: boolean = false;
  private length: number = 0;
  private loadedActivities: Array<Activity> = [];
  public faBullhorn = faBullhorn;
  public faHistory = faHistory;
  private hasAdjustedPadding: boolean = false; // Track if padding is already set
  private previousSelectedAnnouncement: number = this.parserService.selectedAnnouncement; // Track selected announcement to see if it has changed

  constructor(
    private navCtrl: NavController,
    private router: Router,
    private renderer: Renderer2,
    private alertService: AlertService,
    private translateService: TranslateService,
    public localStorage: LocalStorageService,
    private platform: Platform,
    private requestProvider: RequestProviderService,
    public parserService: ParserService,
    public helperService: HelperService,
    private tokenInterceptorService: TokenInterceptorService,
    private requestHelperService: RequestHelperService,
  ) { }

  ngOnInit() {
    //for page refresh
    this.applyStyle();

    document.documentElement.style.setProperty(
      "--backgroundImage",
      'url("../../../assets/imgs/' +
      this.localStorage.getBackground() +
      '.jpg")',
    );

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationStart) {
        // Close announcements on navigation. If the announcements are not closed when navigating, this leads to a bug
        // and the overlay padding can no longer be applied correctly
        this.parserService.showAnnouncements = false;

        // When navigating to a different page and then returning to the first page, the overlay padding may persist if
        // the announcements were previously displayed -> make sure it is removed
        this.resetOverlayPadding();
      }

      if (ev instanceof NavigationEnd) {
        this.language = this.localStorage.getAppLanguage();
        this.translateService.use(this.language);
      }
    });

    if (this.platform.width() < 992) {
      this.smallScreen = true;
    }

    this.platform.resize.subscribe(() => {
      if (this.platform.width() < 992) {
        this.smallScreen = true;
      } else {
        this.smallScreen = false;
      }
    });
  }

  ngAfterViewChecked() {
    // Handle dynamic changes in announcements
    if (this.parserService.showAnnouncements && (!this.hasAdjustedPadding || this.previousSelectedAnnouncement !== this.parserService.selectedAnnouncement)) {
      // Apply a short delay to ensure the DOM has finished rendering the announcements. Without this delay, the 
      // announcements might not be fully loaded into the DOM by the time the overlay padding is adjusted. This
      // can result in incorrect padding-top being applied to .overlayAnnouncements, causing the announcements to 
      // overlap with the content below. The delay ensures the DOM has fully rebuilt itself before the padding 
      // adjustment is made.
      setTimeout(() => this.adjustOverlayPadding(), 15);
    } else if (!this.parserService.showAnnouncements && this.hasAdjustedPadding) {
      // Reset overlay padding when announcements are hidden
      this.resetOverlayPadding();
    }
  }

  private adjustOverlayPadding() {
    if (!this.parserService.showAnnouncements) {
      return;
    }

    const announcementHeight = this.announcementDiv?.nativeElement.offsetHeight ?? 0;
    // There can be multiple elements with class "overlayAnnouncement", all of the must be selected.
    const overlayAnnouncementsElements = document.querySelectorAll(".overlayAnnouncements") as NodeListOf<HTMLElement>;

    overlayAnnouncementsElements.forEach(overlayAnnouncementsElement => {
      this.renderer.setStyle(overlayAnnouncementsElement, "padding-top", `calc(80px + ${announcementHeight}px + env(safe-area-inset-top))`);
      this.hasAdjustedPadding = true;
    })
  }

  resetOverlayPadding() {
    // There can be multiple elements with class "overlayClass", all of the must be selected.
    const overlayClassElements = document.querySelectorAll(".overlayClass") as NodeListOf<HTMLElement>;

    overlayClassElements.forEach((overlayClassElement) => {
      overlayClassElement.style.removeProperty("padding-top"); // Reset padding-top to the default defined in global.scss
    });

    this.hasAdjustedPadding = false;
  }

  menuOpened() {
    this.loadedActivities = [];
    const infiniteScroll = document.getElementById("scrollActivities");
    infiniteScroll.addEventListener("ionInfinite", async function() {
      if (this.length < this.parserService.activityLog.length) {
        console.log("Loading data...");
        await this.wait(500);
        //infiniteScroll.target.complete();
        this.appendItems(10);
        console.log("Done");
      } else {
        console.log("No More Data");
        //infiniteScroll.target.disabled = true;
      }
    });
    this.appendItems(30);
  }

  appendItems(count: number) {
    const end = Math.min(
      this.parserService.activityLog.length,
      this.length + count,
    );
    for (let i = this.length; i < end; i++) {
      this.loadedActivities.push(this.parserService.activityLog[i]);
      this.length++;
    }
  }

  wait(time: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => resolve, time);
    });
  }

  changeLanguage(selectedLanguage: string) {
    if (selectedLanguage !== this.language) {
      this.language = selectedLanguage;
      this.translateService.use(this.language);
      this.localStorage.setAppLanguage(this.language);
      const attributes = {
        settings: {
          locale: selectedLanguage,
        },
      };
      
      if (this.userIsLoggedIn) {
        this.requestProvider
        .updateProfile(attributes)
        .pipe(
          map((result) => result),
          catchError((error) => {
            return throwError(() => error);
          }),
        )
        .subscribe(() => {
          this.localStorage.setAppLanguage(this.language);
        });
      this.tokenInterceptorService.cancelPendingRequests();
      this.changeLanguageEmitter.emit();
      }
    }
  }

  logout() {
    this.alertService.showChoice(
      "GENERAL.LOGOUT",
      "GENERAL.SURE_LOGOUT",
      "BUTTONS.CANCEL",
      "BUTTONS.YES",
      () => { },
      () => {
        // Logout on lesson.page.ts needs to respect answersChanged and answersheetID
        if (this.router.url.startsWith("/lesson/")) {
          this.logoutEmitter.emit();
        } else {
          this.requestHelperService.removeDeviceTokenIdAndLogout();
        }
      },
    );
  }

  private refresh() {
    this.requestProvider
      .refreshToken()
      .then((response) => {
        if (response instanceof HttpResponse && response.status == 200) {
          this.localStorage.setToken(response.body.data.attributes.token);
        }
      })
      .catch((_error) => {
        this.alertService.showError("ERROR.ERROR", "ERROR.ERROR_TOKEN_REFRESH");
      });
  }

  goBack() {
    this.navCtrl.back();
  }

  routeToInvitations() {
    this.router.navigateByUrl("/invitations");
  }

  saveQuestionnaire() {
    this.saveEmitter.emit();
  }

  showHideAnnouncements() {
    this.parserService.showAnnouncements =
      !this.parserService.showAnnouncements;
  }

  previousAnnouncement() {
    this.parserService.selectedAnnouncement--;
  }

  nextAnnouncement() {
    this.parserService.selectedAnnouncement++;
  }

  /**
   * Toggles the document body's class based on the user's theme preference and login status.
   * 
   * If the user is logged in, it toggles the "darkTheme" class based on the theme stored in localStorage.
   * If the theme is "0", it implies a dark theme, and the "darkTheme" class is added to the document body.
   * If the theme is "1", it implies a light theme, and the default body CSS properties will be applied.
   * If the user is not logged in, it simply adds the "loggedOut" class to the document body.
   */
  applyStyle(): void {
    if (this.userIsLoggedIn) {
      document.body.classList.toggle(
        "darkTheme",
        this.localStorage.getTheme() == "0",
     );
    } else {
      document.body.classList.toggle("loggedOut",true);
      document.body.classList.toggle("darkTheme",false);
    }
  }

  emit() { }

  get isMobile(): boolean {
    return this.platform.is("mobileweb");
  }

  get isDevelopVersion(): boolean {
    return !BACKEND_URL.includes("esano");
  }
}

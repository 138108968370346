import { Component, OnInit, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Question } from "../Question";
import { QuestionDateModel } from "src/app/models/elements/QuestionDateModel";

type IonDatetimePresentation =
  | "date"
  | "date-time"
  | "month"
  | "month-year"
  | "time"
  | "time-date"
  | "year";

@Component({
  selector: "app-text-date",
  templateUrl: "./text-date.component.html",
  styleUrls: ["./text-date.component.scss"],
})
export class Date_ extends Question implements OnInit {
  @Input() public loopIndex: number;
  model: QuestionDateModel;
  initDate: string | null;
  showCalendar: boolean = false;
  selectedDateTime: string | undefined = undefined;
  locale: string = "en";
  presentation: IonDatetimePresentation = "date-time";
  pickerDate: string = "";

  constructor(private translateService: TranslateService) {
    super();
  }

  ngOnInit() {
    this.locale = this.translateService.instant("LESSON.DATE_FORMAT_LAN");
    if (this.model.type === "date") {
      this.presentation = "date";
    } else if (this.model.type === "time") {
      this.presentation = "time";
    }

    this.initDate = this.currentValue;
    if (this.initDate) {
      this.selectedDateTime = this.getPickerDateFromUTC(this.initDate);
    } else {
      this.selectedDateTime = this.initDate;
    }

    // emit null (so that there is an entry for each repetition) or saved answer
    if (!this.currentValue) {
      this.initAnswerArray();
    }
  }

  ngOnDestroy() {
    console.log(
      "destroy pos " + this.model.position + " loop" + this.loopIndex,
    );
    this.model.lesson.removeAnswerRepetition(
      this.model.position,
      this.loopIndex,
    );
  }

  public emitAnswer() {
    if (this.selectedDateTime === undefined || this.model.lesson.loading) {
      return;
    }
    //selected value is date in timezone of user -> has to be in utc
    //example: user selects "30.1.23 0 uhr"
    //value of the picker is "Mon Jan 30 2023 00:00:00 GMT+0100" -> the correct time but shown in timezone of the user
    //we want exactly this time "30.1.23 0 uhr" but saved in utc, so it is the same no matter where it was saved
    //saved answer is: 2023-01-30T00:00:00.000Z
    //for init see comment above
    let answer: string | null = null;
    if (this.selectedDateTime) {
      answer = new Date(this.selectedDateTime).toISOString();
    }
    this.model.lesson.insertAnswer(this.model.position, this.loopIndex, answer);
    const inserted: string = this.currentValue;
    if (inserted) {
      this.initDate = inserted;
    }
  }

  initAnswerArray(): void {
    // This function initializes spaces with the value null in the responses array, thus creating
    // slots to store data in them.
    this.model.lesson.insertAnswer(this.model.position, this.loopIndex, null);
  }

  get currentValue(): string | null {
    return this.model.lesson.getAnswer(this.model.position, this.loopIndex);
  }

  formatDate(utcDateString: string): string {
    const utcDate = new Date(utcDateString);
    switch (this.presentation) {
      case "date-time": {
        return utcDate.toLocaleDateString(this.locale, {
          day: "numeric",
          month: "long",
          year: "numeric",
          hour: "numeric",
          minute: "2-digit",
          hour12: this.translateService.instant("LESSON.DATE_AMPM") !== "false",
          weekday: "long",
        });
      }
      case "date": {
        return utcDate.toLocaleDateString(this.locale, {
          day: "numeric",
          month: "long",
          year: "numeric",
          weekday: "long",
        });
      }
      case "time": {
        return utcDate.toLocaleString(this.locale, {
          hour: "numeric",
          minute: "2-digit",
          hour12: this.translateService.instant("LESSON.DATE_AMPM") !== "false",
        });
      }
    }
  }

  openDatePicker() {
    if (this.selectedDateTime) {
      this.pickerDate = this.selectedDateTime;
    } else {
      this.pickerDate = this.getPickerDateFromUTC();
    }
    this.showCalendar = true;
  }

  closeDatePicker() {
    this.showCalendar = false;
  }

  // ion-datetime ignores timezone, so we have to add the hours from the timezone to display the correct date, since we use UTC dates.
  // E.g. "2024-10-20T10:25:00.000Z" would not be interpreted as 2024-10-20 at 12:25 in timezone GMT+0200, but as 2024-10-20 at 10:25.
  // Offset gets subtracted, because it's the value added to the timezone to get the UTC date, meaning its sign is flipped.
  getPickerDateFromUTC(date?: string): string {
    const utcDate = date === undefined ? new Date() : new Date(date);
    const MIN_TO_MS = 60000;
    const offsetTime = utcDate.getTime() - utcDate.getTimezoneOffset() * MIN_TO_MS;
    return new Date(offsetTime).toISOString();
  }
}

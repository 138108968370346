import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  GuardResult,
  MaybeAsync,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { of } from "rxjs";
import { FEATURES } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class SkillFeatureGuard {
  private features = FEATURES;

  constructor(private router: Router) { }

  canActivate(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot,
  ): MaybeAsync<GuardResult> {
    if (this.features.includes("skills")) {
      return of(true);
    } else {
      this.router.navigateByUrl("/home");
      return of(false);
    }
  }
}

export class Message {
  id: number = 0;
  authorId: number = 0;
  authorName: string = "";
  message: string = "";
  updatedAt: number = 0;

  constructor(
    id: number,
    authorId: number,
    authorName: string,
    message: string,
    updatedAt: number,
  ) {
    this.id = id;
    this.authorId = authorId;
    this.authorName = authorName;
    this.message = message;
    this.updatedAt = updatedAt;
  }
}

import { Component, OnInit, Input } from "@angular/core";
import { Element } from "../Element/element.component";
import { BlockModel } from "../../../models/elements/BlockModel";
import { BlockType } from "src/app/models/elements/Enums";
var evaluationService = require("@aas2/aas2-evaluation-service");

@Component({
  selector: "app-block",
  templateUrl: "./block.component.html",
  styleUrls: ["./block.component.scss"],
})
export class Block extends Element implements OnInit {
  @Input() public loopIndex: number;
  public model: BlockModel;
  public hideElements: boolean;
  public hideDetails: boolean;
  public repeat: number = 1;

  constructor() {
    super();
    this.model = this.model as BlockModel;
  }

  ngOnInit() {
    this.hideElements = this.model.type !== BlockType.NONE;
    this.hideDetails = this.model.type === BlockType.BOTH;

    if (!this.isDynamicLoop()) {
      this.repeat = this.model.repeat["data"];
    } else {
      //reinit quicksaved, language change, answersheet
      this.setRepetition();
      this.model.lesson.answers.subscribe((_x) => {
        //on change of answers re-evaluate
        this.setRepetition();
      });
    }

    if (this.isConditional()) {
      //reinit quicksaved, language change, answersheet
      this.evaluateBlock();
      this.model.lesson.answers.subscribe((_x) => {
        //on change of answers re-evaluate
        this.evaluateBlock();
      });
    }
  }

  setRepetition() {
    const answer = this.model.lesson.getAnswer(this.model.repeat["data"]);
    if (answer != null) {
      this.repeat = answer;
    }
  }

  evaluateBlock() {
    const hide = !evaluationService.evaluateCondition(
      this.model.condition,
      this.model.lesson._answers,
    );
    if (this.model.type === BlockType.BOTH) {
      this.hideDetails = hide;
    } else {
      this.hideElements = hide;
    }
  }

  get numberOfRepetitions(): number[] {
    return Array.from({ length: this.repeat }, (_v, k) => k + 1);
  }

  toggleBlock() {
    this.hideElements = !this.hideElements;
  }

  // isStaticLoop(): boolean {
  //   return !(this.model['repeat']['question'] && this.model['repeat']['data']);
  // }

  isDynamicLoop(): boolean {
    return this.model["repeat"]["question"];
  }

  isConditional(): boolean {
    return (
      this.model.type === BlockType.CONDITIONAL ||
      this.model.type === BlockType.BOTH
    );
  }

  hasDetails(): boolean {
    return (
      this.model.type === BlockType.DETAILS ||
      this.model.type === BlockType.BOTH
    );
  }
}

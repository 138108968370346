import { MediaType } from "../../../app/models/elements/Enums";
import { ElementModel } from "./ElementModel";

export class MediaModel extends ElementModel {
  resolvable = ["subtitle", "description"];
  subtitle: string;
  subtitleResolved: string;
  description: string;
  descriptionResolved: string;
  type: MediaType;
  webUri: string;
  isAudio: boolean;
  filename: string;
  videoSubtitleUri: string;
  videoSubtitleOriginalName: string;
  width: string;
  banner: boolean;

  constructor(
    position: number,
    type: MediaType,
    isAudio: boolean,
    webUri: string,
    description: string,
    subtitle: string,
    filename: string,
    videoSubtitleUri: string | null | undefined,
    videoSubtitleOriginalName: string | null | undefined,
    width: string,
    banner: boolean,
  ) {
    super(position);
    this.type = type;
    this.webUri = webUri;
    this.isAudio = isAudio;
    this.description = description;
    this.subtitle = subtitle;
    this.filename = filename;
    this.videoSubtitleUri = videoSubtitleUri ?? "";
    this.videoSubtitleOriginalName = videoSubtitleOriginalName ?? "";
    this.width = width;
    this.banner = banner;
  }
}

<ion-modal [isOpen]="isModalOpen">
  <ng-template>
    <ng-container *ngIf="isModalOpen">
      <ion-header>
        <ion-toolbar>
          <ion-title>{{ "PERMISSION_ONBOARDING_AND_DIARY_SENSOR_INFO.ONBOARDING_TITLE" | translate }}</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding content">
        <div class="icon-container">
          <ion-icon class="alert-icon" [name]="currentIcon"></ion-icon>
        </div>
        <h1>{{ currentPermissionTitle }}</h1>
        <p>{{ currentPermissionText }}</p>
        <div class="warning-box" *ngIf="warningBoxVisible">
          <div class="alert-header">
            <ion-icon class="alert-icon" name="warning"></ion-icon>
            <h3>{{ "PERMISSION_ONBOARDING_AND_DIARY_SENSOR_INFO.ONBOARDING_PERMISSION_DENIED_TITLE" | translate }}</h3>
          </div>
          <p class="alert-text">{{ permissionDeniedText }}</p>
          <div class="button-wrapper" *ngIf="retryPermissionRequestButtonVisible">
            <ion-button class="retry-button" (click)="retryButtonClicked()">{{ "BUTTONS.RETRY_PERMISSION_REQUEST" |
              translate}}</ion-button>
          </div>
        </div>
        <div class="button-wrapper">
          <ion-button class="continue-button" (click)="next()">{{ "BUTTONS.CONTINUE" | translate }}</ion-button>
        </div>
      </ion-content>
    </ng-container>
  </ng-template>
</ion-modal>
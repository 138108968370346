<div class="diary-sensor-hint-container" *ngIf="pedometerIsUsed || locationIsUsed">
  <div class="icon-container" (click)="setModalIsOpen(true, $event)">
    <ng-container *ngIf="pedometerIsUsed">
      <div class="icon-circle">
        <ion-icon slot="icon-only" name="footsteps-sharp"></ion-icon>
        <div class="cross-out-line" *ngIf="!pedometerPermissionGranted"></div>
      </div>
    </ng-container>

    <ng-container *ngIf="locationIsUsed">
      <div class="icon-circle">
        <ion-icon slot="icon-only" name="location-sharp"></ion-icon>
        <div class="cross-out-line" *ngIf="!locationPermissionGranted"></div>
      </div>
    </ng-container>
  </div>
</div>

<ion-modal #modal [isOpen]="modalIsOpen" (willDismiss)="setModalIsOpen(false)" [initialBreakpoint]="0.75" [breakpoints]="[0,0.75,1]" *ngIf="pedometerIsUsed || locationIsUsed">
  <ng-template>
    <ion-content>
      <div class="diary-sensor-info-card">
        <h1>{{ "PERMISSION_ONBOARDING_AND_DIARY_SENSOR_INFO.SENSOR_INFO_TITLE" | translate }}</h1>
        <p>{{ "PERMISSION_ONBOARDING_AND_DIARY_SENSOR_INFO.SENSOR_INFO_DESCRIPTION" | translate }}</p>

        <ng-container *ngIf="pedometerIsUsed">
          <div class="sensor-info">
            <div class="sensor-info-title">
              <div class="icon-circle">
                <ion-icon slot="icon-only" name="footsteps-sharp"></ion-icon>
                <div class="cross-out-line" *ngIf="!pedometerPermissionGranted"></div>
              </div>
              <h4>
                {{ "PERMISSION_ONBOARDING_AND_DIARY_SENSOR_INFO.SENSOR_INFO_PEDOMETER_TITLE" | translate }}
                <span *ngIf="!pedometerPermissionGranted">({{ "PERMISSION_ONBOARDING_AND_DIARY_SENSOR_INFO.SENSOR_INFO_MISSING_PERMISSION" | translate }})</span>
              </h4>
            </div>
            <p>{{ "PERMISSION_ONBOARDING_AND_DIARY_SENSOR_INFO.PEDOMETER_DATA_COLLECTION_REASON" | translate }}</p>
          </div>
        </ng-container>

        <ng-container *ngIf="locationIsUsed">
          <div class="sensor-info">
            <div class="sensor-info-title">
              <div class="icon-circle">
                <ion-icon slot="icon-only" name="location-sharp"></ion-icon>
                <div class="cross-out-line" *ngIf="!locationPermissionGranted"></div>
              </div>
              <h4>
                {{ "PERMISSION_ONBOARDING_AND_DIARY_SENSOR_INFO.SENSOR_INFO_LOCATION_TITLE" | translate }}
                <span *ngIf="!locationPermissionGranted">({{ "PERMISSION_ONBOARDING_AND_DIARY_SENSOR_INFO.SENSOR_INFO_MISSING_PERMISSION" | translate }})</span>
              </h4>
            </div>
            <p>{{ "PERMISSION_ONBOARDING_AND_DIARY_SENSOR_INFO.LOCATION_DATA_COLLECTION_REASON" | translate }}</p>
          </div>
        </ng-container>

        <ng-container *ngIf="pedometerIsUsed && !pedometerPermissionGranted || locationIsUsed && !locationPermissionGranted">
          <div class="missing-permission-box">
            <div class="info-icon">
              <ion-icon name="information-circle"></ion-icon>
            </div>
            <p>
              {{ "PERMISSION_ONBOARDING_AND_DIARY_SENSOR_INFO.SENSOR_INFO_MISSING_PERMISSION_ADDITIONAL_INFO" | translate }}
            </p>
          </div>          
        </ng-container>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>

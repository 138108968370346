<ion-header *ngIf="thread != null">
  <ion-toolbar id="chat-toolbar">
    <ion-buttons slot="secondary">
      <ion-button
        id="showChatInfoButton"
        (click)="presentChatInformation()"
        style="color: white"
      >
        <ion-icon slot="icon-only" name="people"></ion-icon>
      </ion-button>
    </ion-buttons>
    <div class="subject">
      <ion-title *ngIf="thread.subject">
        {{ thread.subject }}
      </ion-title>
    </div>
  </ion-toolbar>
</ion-header>
<ion-fab
  [class.marginTop]="!fullSize"
  [class.moreMargin]="fullSize"
  [class.paddingAnnouncement]="parserService.showAnnouncements"
  vertical="top"
  horizontal="end"
  slot="fixed"
>
  <ion-fab-button
    size="small"
    id="scrollDownButton"
    (click)="scrollBottomSlow()"
  >
    <ion-icon name="arrow-down"></ion-icon>
  </ion-fab-button>
</ion-fab>
<ion-content #chatContentRef appScrollbarTheme [style.height.px]="height">
  <ion-grid *ngIf="thread">
    <ion-row
      *ngFor="let message of thread.messages; let i = index"
      [attr.id]="'message' + i"
    >
      <ion-col
        size="9"
        sizeSm="9"
        sizeMd="6"
        sizeLg="7"
        sizeXl="5"
        offset="{{ getOffsetSize(message, 0) }}"
        offsetSm="{{ getOffsetSize(message, 1) }}"
        offsetMd="{{ getOffsetSize(message, 2) }}"
        offsetLg="{{ getOffsetSize(message, 3) }}"
        offsetXl="{{ getOffsetSize(message, 4) }}"
        class="message"
      >
        <p
          style="
            font-size: 16px;
            height: 9px;
            font-weight: 400;
            color: floralwhite;
          "
        >
          {{ message.authorName }}
        </p>
        <div
          [class.my-message]="message.authorId == myUserId"
          [class.other-message]="message.authorId != myUserId"
        >
          <span>
            {{ message.message }}
          </span>
          <br />
          <div
            class="time ion-text-right"
            style="padding-top: 30px; position: relative; right: -19px"
          >
            {{ message.updatedAt | date: "dd.MM.yyyy, HH:mm" }}
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-row class="ion-align-items-center ion-no-padding">
      <ion-col size="10">
        <textarea
          [(ngModel)]="newMsg"
          id="inputMessageChat"
          autosize
          class="message-input"
          maxRows="4"
        ></textarea>
      </ion-col>
      <ion-col size="2" class="msg-btn-col">
        <ion-button
          expand="block"
          fill="clear"
          color="primary"
          [disabled]="!newMsg.trim()"
          id="sendMessageButton"
          (click)="sendMessage()"
        >
          <ion-icon
            name="send"
            slot="icon-only"
            style="color: white"
          ></ion-icon>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-toolbar>
</ion-footer>

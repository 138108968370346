<div [class.required]="model.required" class="ion-text-left question">
  <ion-label
    [class.answerRequired]="model.highlight && currentValue == null"
    class="ion-text-wrap"
    color="page-font"
  >
    {{ model.questionResolved }}
  </ion-label>
  <div class="slider" fxLayout="column">
    <div class="range-slider-labels">
      <ion-label class="labelLeft" slot="start" [class.disabled]="model.isDisabled">
        {{ model.labelLeft }}
      </ion-label>
      <h4>
        {{ value }}
      </h4>
      <ion-label class="labelRight" slot="end">
        {{ model.labelRight }}
      </ion-label>
    </div>
    <ion-item class="ion-no-padding" lines="none">
      <ion-range
        [value]="currentValue ?? model.start"
        [min]="model.scaleRangeLeft"
        [max]="model.scaleRangeRight"
        [step]="model.stepWidth"
        [snaps]="true"
        [class.disabled]="model.isDisabled"
        [class.notAnswered]="currentValue == null"
        (ionFocus)="rangeFocused($event)"
        (ionChange)="emitAnswer($event.detail.value)"
        (ionInput)="dragSlider($event.detail.value)"
      ></ion-range>
      <ion-button
        slot="start"
        fill="clear"
        icon-only
        *ngIf="manyPoints"
        (click)="onSubtractFromRange()"
      >
        <ion-icon name="remove-circle-outline"></ion-icon>
      </ion-button>
      <ion-button
        slot="end"
        fill="clear"
        icon-only
        *ngIf="manyPoints"
        (click)="onAddToRange()"
      >
        <ion-icon name="add-circle-outline"></ion-icon>
      </ion-button>
    </ion-item>
  </div>
</div>

import { Component, Input, OnInit } from "@angular/core";
import { Question } from "../Question";
import { QuestionSliderModel } from "../../../models/elements/QuestionSliderModel";

@Component({
  selector: "app-slider",
  templateUrl: "./slider.component.html",
  styleUrls: ["./slider.component.scss"],
})
export class Slider extends Question implements OnInit {
  @Input() public loopIndex: number;
  public model: QuestionSliderModel;
  manyPoints: boolean = false;
  value: number | null = null;

  constructor() {
    super();
  }

  ngAfterViewInit() {
    //emit null (so that there is an entry for each repetition) or saved answer
    this.model.scaleRangeLeft = 0;
    this.emitAnswer(this.currentValue);
  }

  public ngOnInit() {
    this.manyPoints = this.model ? this.model.scaleRangeRight > 20 : false;
  }

  ngOnDestroy() {
    this.model.lesson.removeAnswerRepetition(this.model.position, this.loopIndex);
  }

  public onAddToRange(): void {
    if (this.value == null) {
      this.emitAnswer(this.model.start + 1);
    } else if (this.value < this.model.scaleRangeRight) {
      this.value++;
      this.emitAnswer(this.value);
    }
  }

  public onSubtractFromRange() {
    if (this.value == null) {
      this.emitAnswer(this.model.start - 1);
    } else if (this.value > this.model.scaleRangeLeft) {
      this.value--;
      this.emitAnswer(this.value);
    }
  }

  public rangeFocused(_value: CustomEvent) {
    //this is only used for when nothing selected and then start value should be selected
    if (this.currentValue === null) {
      this.emitAnswer(this.model.start);
    }
  }

  public emitAnswer(value: number) {
    if (value === undefined || this.model.lesson.loading) {
      return;
    }
    this.model.lesson.insertAnswer(this.model.position, this.loopIndex, value);
    this.value = this.currentValue;
  }

  dragSlider(value: number) {
    this.value = value;
  }

  get currentValue(): number {
    return this.model.lesson.getAnswer(this.model.position, this.loopIndex);
  }
}

import {
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { IonContent, ModalController, Platform } from "@ionic/angular";
import { ChatInformationComponent } from "../dialogs/chat-information/chat-information.component";
import { ParserService } from "../../providers/parser/parser.service";
import { RequestProviderService } from "../../providers/request-provider/request-provider.service";
import { LocalStorageService } from "../../providers/local-storage/local-storage.service";
import { AlertService } from "../../providers/alert/alert.service";
import { Message } from "src/app/models/Message";
import { Thread } from "src/app/models/Thread";

@Component({
  selector: "app-chat-component",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
})
export class ChatComponent implements OnInit, OnChanges {
  @Input() fullSize: boolean;
  @Input() thread: Thread;
  @Output("sentMessage") sentMessage = new EventEmitter<Event>();
  @ViewChild("chatContentRef", { static: false })
  private chatContentRef: IonContent;

  private myUserId: number;
  public newMsg: string = "";
  public height: number;

  constructor(
    private platform: Platform,
    private modalController: ModalController,
    public parserService: ParserService,
    private requestProvider: RequestProviderService,
    private localStorage: LocalStorageService,
    private alertService: AlertService,
  ) { }

  ngOnChanges() {
    setTimeout(() => this.scrollToBottom(), 500);
  }

  ngOnInit() {
    this.myUserId = this.localStorage.getUserId();
    const heightSub = this.fullSize ? 175 : 300;
    this.height = this.platform.height() - heightSub;
    this.platform.resize.subscribe(() => {
      this.height = this.platform.height() - heightSub;
    });
  }

  /**
   * @method sendMessage
   *
   * Sends message from input field into thread. Emits event to get new messages, scrolls down on change.
   */
  sendMessage() {
    this.requestProvider.sendMessage(this.newMsg, this.thread.id).subscribe({
      next: (_response) => {
        this.newMsg = "";
        this.sentMessage.emit();
      },
      error: (_error) => {
        this.alertService.showError("ERROR.ERROR", "ERROR.ERROR_SEND_MESSAGE");
      },
    });
  }

  /**
   * @method presentChatInformation
   *
   * Shows available information about the current thread via a modal.
   */
  async presentChatInformation() {
    const modal = await this.modalController.create({
      component: ChatInformationComponent,
      cssClass: "modal-css",
      componentProps: {
        users: this.thread.participants,
      },
    });
    return await modal.present();
  }

  scrollBottomSlow() {
    this.chatContentRef.scrollToBottom(300);
  }

  scrollToBottom() {
    this.chatContentRef.scrollToBottom(0);
  }

  getOffsetSize(message: Message, size: number) {
    if (message.authorId != this.myUserId) {
      if (size === 0 || size === 3) {
        return 0;
      } else if (size === 1 || size === 2) {
        return 1;
      } else {
        return 2;
      }
    } else {
      if (size === 0 || size === 1) {
        return 3;
      } else {
        return 5;
      }
    }
  }
}

import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { HttpErrorResponse } from "@angular/common/http";
import { Thread } from "../../../models/Thread";
import { ParserService } from "../../../providers/parser/parser.service";
import { RequestProviderService } from "../../../providers/request-provider/request-provider.service";
import { AlertService } from "../../../providers/alert/alert.service";
import { ChatComponent } from "../../chat/chat.component";
import { AppComponent } from "src/app/app.component";

@Component({
  selector: "app-feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.scss"],
})
export class FeedbackComponent implements OnInit {
  @Input() thread: Thread;

  @ViewChild(ChatComponent, { static: false })
  private chatComponent: ChatComponent;

  public newMsg: string;

  constructor(
    private modalController: ModalController,
    private parserService: ParserService,
    private requestProvider: RequestProviderService,
    private alertService: AlertService,
    private appComponent: AppComponent,
  ) { }

  ngOnInit() {
    this.appComponent.overwriteDefaultBackButtonListener(() => {
      this.dismiss();
    });
    setTimeout(() => {
      this.chatComponent.scrollToBottom();
    }, 500);

    if (this.thread.lessonFeedbackRequired && !this.thread.read) {
      const progressJSON = {
        data: {
          type: "intervention_instance",
          attributes: {
            progress: {
              feedback_provided: {
                questionnaire_id: this.thread.lessonId,
                thread_id: this.thread.id,
                read: true,
                answersheet_id: this.thread.answersheetId,
              },
            },
          },
        },
      };
      this.requestProvider
        .setFeedbackRead(this.thread.assignedInstanceID, progressJSON)
        .subscribe({
          next: (response) => {
            if (response instanceof HttpErrorResponse) {
              //feedback can not be set to read, try to set thread read by requesting thread details
              this.getNewMessages(true);
            } else {
              this.thread.read = true;
              this.parserService.newFeedback--;
            }
          },
          error: (_error) => {
            //feedback can not be set to read, try to set thread read by requesting thread details
            this.getNewMessages(true);
          },
        });
    } else {
      if (!this.thread.read) {
        this.parserService.newFeedback--;
      }
      this.thread.read = true;
    }
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
    this.appComponent.applyDefaultBackButtonListener();
  }

  /**
   * @method sendMessage
   *
   * Sends the message which is contained in the input field. Sends it into the
   * thread which is currently active. Then pulls the messages of the chat
   * (Sent by the user), pushes them into view and scrolls down.
   */
  onSentMessage() {
    this.getNewMessages(false);
  }

  getNewMessages(readFeedback: boolean) {
    this.requestProvider.getMessageThread(this.thread.id).subscribe({
      next: (response) => {
        this.thread = this.parserService.parseThread(response);
        setTimeout(() => {
          this.chatComponent.scrollBottomSlow();
        }, 500);
      },
      error: (_error) => {
        this.handleErrorGetMessages(readFeedback);
      },
    });
  }

  handleErrorGetMessages(readFeedback: boolean) {
    if (readFeedback) {
      this.alertService.showError("ERROR.ERROR", "ERROR.ERROR_READ_FEEDBACK");
    } else {
      this.alertService.showError("ERROR.ERROR", "ERROR.ERROR_DATA_LOADING");
    }
  }

  scrollBottomSlow() {
    this.chatComponent.scrollBottomSlow();
  }
}

<ion-header>
  <ion-toolbar class="header">
    <ion-title *ngIf="thread.answersheetId !== null" class="whiteFont">
      {{ "CHAT_FEEDBACK.FEEDBACK" | translate }}
    </ion-title>
    <ion-title *ngIf="thread.answersheetId == null" class="whiteFont">
      {{ "CHAT_FEEDBACK.CHAT_MESSAGES" | translate }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button id="closeButton" (click)="dismiss()">
        <ion-icon slot="icon-only" name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content appScrollbarTheme>
  <app-chat-component
    [thread]="thread"
    [fullSize]="true"
    (sentMessage)="onSentMessage()"
  ></app-chat-component>
</ion-content>

import { Component } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from "@ngx-translate/core";
import { LocalNotifications } from '@capacitor/local-notifications'
import { SensingService } from '@aas2/aas2-sensing-service';
import { Filesystem } from '@capacitor/filesystem';
import { DiaryInstance } from 'src/app/models/DiaryInstance';
import { AlertService } from 'src/app/providers/alert/alert.service';
import { AppComponent } from 'src/app/app.component';

/**
 * The `PermissionManager` component manages both the permission onboarding dialog UI and the permission logic.
 * 
 * The permission logic can function independently of the onboarding dialog.
 * 
 * **Using the UI dialog:** To utilize the onboarding dialog, ensure the `<permission-onboarding></permission-onboarding>` element is included in another component's HTML file.
 * Also, make sure to use an Angular **reference variable** within the tag, for example: `<permission-onboarding #ref></permission-onboarding>`.
 * This reference variable must be utilized in the source code, such as in an object attribute: `@ViewChild("ref") private permissionManager: PermissionManager`.
 * 
 * The primary methods of a `PermissionManager` instance include `checkAndMayAskForNativePermissions`, `requestSinglePermission`, and `getPermissionStatus`.
 */
@Component({
  selector: 'permission-onboarding',
  templateUrl: './permission-manager.component.html',
  styleUrls: ['./permission-manager.component.scss'],
})
export class PermissionManager {
  /** `true` if the dialog is currently open, otherwise `false`. */
  public isModalOpen: boolean = false;

  /** This is the current index of the permission that is currently displayed in the dialog. */
  private permissionListIndex: number = 0;

  /** This list contains all permissions that must be requested from the user in the current dialog lifecycle. */
  public permissionsToBeRequested: Permission[] = [];

  /** The value for the `name` attribute in the `ion-icon` HTML tag that represents a specific icon for a permission. */
  public currentIcon: string = "";

  /** The title of the permission request that is currently requested from the user. */
  public currentPermissionTitle: string = "";

  /** The description of the permission request that is currently requested from the user. It contains information on why the current permission should be acquired. */
  public currentPermissionText: string = "";

  /** `true`if the warning box is currently visible within the dialog, otherwise `false`. */
  public warningBoxVisible: boolean = false;

  /** This title belongs to the red box which appears, when the user has declined a permission. */
  public permissionDeniedTitle: string = ""

  /** This text contains information on how the current denied permission can still be granted. */
  public permissionDeniedText: string = "";

  /** Is used to maintain the state of current dialog. `true` if the permission was previously requested from the user by a system dialog, otherwise `false`. */
  public permissionAlreadyRequested: boolean = false;

  /** Is used to determine whether the retry button within the red warning box should be visible. */
  public retryPermissionRequestButtonVisible: boolean = false;

  /** `true` if the notification permission has been already granted before initialization of the permission manager or has been granted within the permission dialog, otherwise `false`. */
  public notificationPermissionGranted: boolean = false;

  /** `true` if the pedometer permission has been already granted before initialization of the permission manager or has been granted within the permission dialog, otherwise `false`. */
  public pedometerPermissionGranted: boolean = false;

  /** `true` if the coarse location permission has been already granted before initialization of the permission manager or has been granted within the permission dialog, otherwise `false`. */
  public coarseLocationPermissionGranted: boolean = false;

  /** `true` if the fine location permission has been already granted before initialization of the permission manager or has been granted within the permission dialog, otherwise `false`. */
  public fineLocationPermissionGranted: boolean = false;

  /** `true` if the storage permission has been already granted before initialization of the permission manager or has been granted within the permission dialog, otherwise `false`. */
  public storagePermissionGranted: boolean = false;

  /** This is a resolve function of a promise that is returned immediately by calling the permission manager to open a dialog. This function is called with the current permission status values after closing the dialog to resolve the promise. */
  private resolvePermissionRequest: (permissionStatus: BooleanPermissionStatus) => void = (_) => { };

  constructor(
    private translateService: TranslateService,
    private alertService: AlertService,
    private appComponent: AppComponent,
  ) { }

  /**
   * This function checks and may request permissions required for certain features in the app, based on the provided permission options.
   *
   * If certain permissions (e.g., notification, pedometer, location, or storage) are required and have not been granted, the system will prompt the user to grant them.
   * Permissions that were previously denied are not requested again. The function returns a `BooleanPermissionStatus` object that indicates whether each permission has been granted.
   * 
   * **Important Notes:**
   * - On web platforms, no permission requests are made, and the function simply returns a `BooleanPermissionStatus` with all values set to `false`.
   * - On native platforms (iOS/Android), the appropriate system dialogs are displayed for permissions that require user approval.
   * 
   * ### Permission Handling (`permissionOptions` parameter object attributes):
   * - **singlePermissions:** These individual permissions (e.g., `"storage"`, `"location"`) can be specified directly through `permissionOptions.singlePermissions`.
   * - **diaryInstances:** If specified in `permissionOptions.diaryInstances`, permissions may also be derived from the provided instances (e.g., if diary instances contain schedules or sensor data like pedometer or location).
   *
   * ### Permission Request Workflow:
   * - If a permission is required and has the status `prompt`, the user will be asked to grant it.
   * - If all required permissions have already been granted or denied, no system dialogs will be shown.
   * 
   * @param permissionOptions The options object that contains either single permissions or diary instances defining the required permissions.
   * @returns A `Promise` with a `BooleanPermissionStatus`, indicating whether each requested permission has been granted (`true`) or denied (`false`).
   */
  public async checkAndMayAskForNativePermissions(permissionOptions: RequiredPermissionOptions): Promise<BooleanPermissionStatus> {
    if (!Capacitor.isNativePlatform()) {
      return {
        notification: false,
        pedometer: false,
        fineLocation: false,
        coarseLocation: false,
        storage: false
      }
    }

    // get requested permission of the caller of this function
    let notificationPermissionRequired = permissionOptions.singlePermissions?.includes("notification") ?? false;
    let pedometerPermissionRequired = permissionOptions.singlePermissions?.includes("pedometer") ?? false;
    let locationPermissionRequired = permissionOptions.singlePermissions?.includes("location") ?? false;
    let storagePermissionRequired = permissionOptions.singlePermissions?.includes("storage") ?? false;

    if (permissionOptions.diaryInstances) {
      for (const diaryInstance of permissionOptions.diaryInstances) {
        if (diaryInstance.schedules.length) {
          notificationPermissionRequired = true;
        }

        if (diaryInstance.sensorData.some(sensor => sensor === "pedometer")) {
          pedometerPermissionRequired = true;
        }

        if (diaryInstance.sensorData.some(sensor => sensor === "location")) {
          locationPermissionRequired = true;
        }
      }
    }

    // get the permission status of all possible permissions
    const permissionStatus = await this.getPermissionStatus();

    // set the granted permissions
    this.notificationPermissionGranted = permissionStatus.notification === "granted";
    this.pedometerPermissionGranted = permissionStatus.pedometer === "granted";
    this.coarseLocationPermissionGranted = permissionStatus.coarseLocation === "granted";
    this.fineLocationPermissionGranted = permissionStatus.fineLocation === "granted";

    // determine permissions that were requested and not previously displayed in a system dialog.
    if (notificationPermissionRequired && permissionStatus.notification === "prompt") {
      this.permissionsToBeRequested.push("notification");
    }

    if (pedometerPermissionRequired && permissionStatus.pedometer === "prompt") {
      this.permissionsToBeRequested.push("pedometer");
    }

    if (locationPermissionRequired && permissionStatus.fineLocation === "prompt") {
      this.permissionsToBeRequested.push("location");
    }

    if (storagePermissionRequired && permissionStatus.storage === "prompt") {
      this.permissionsToBeRequested.push("storage");
    }

    // There are no permissions that can be granted -> return the current permission status
    if (this.permissionsToBeRequested.length === 0) {
      return {
        notification: permissionStatus.notification === "granted",
        pedometer: permissionStatus.pedometer === "granted",
        coarseLocation: permissionStatus.coarseLocation === "granted",
        fineLocation: permissionStatus.fineLocation === "granted",
        storage: permissionStatus.storage === "granted"
      }
    }

    this.updateDialogContent();
    this.openPermissionOnboardingDialog();

    // This promise is later resolved within another method in this instance.
    return new Promise(resolve => { this.resolvePermissionRequest = resolve });
  }

  /**
   * This function is triggered by the next button in the permission dialog which updates the dialog content.
   * 
   * @returns A `Promise` that resolves when the current case was completed.
   */
  async next(): Promise<void> {
    // case 1: the current permission in the permission is the last permission and the system dialog was previously opened
    if (this.permissionsToBeRequested.length - 1 === this.permissionListIndex && this.permissionAlreadyRequested) {
      this.closePermissionDialogAndResolveRequest();
      return;
    }

    // case 2: a system dialog for the current permission was previously shown and in this step the details of the next permission can be shown
    if (this.permissionAlreadyRequested) {
      this.permissionAlreadyRequested = false;
      this.removeWarningBox();
      this.permissionListIndex += 1;
      this.updateDialogContent();
      return;
    }

    // case 3: The user has only read the information about the current permission and opens the system dialog with the next button
    const permissionRequestStatus = await this.requestCurrentPermissionOnSystem();

    if (permissionRequestStatus !== "granted") {
      this.showWarningBox(permissionRequestStatus);
    } else {
      this.removeWarningBox();
      this.next();
    }
  }

  /**
   * This function is called by the button in the red warning box. It tries to request the current permission from the user.
   */
  async retryButtonClicked(): Promise<void> {
    const permissionRequestStatus = await this.requestCurrentPermissionOnSystem();

    if (permissionRequestStatus === "granted") {
      this.removeWarningBox();
      this.next();
    } else if (permissionRequestStatus !== "prompt-with-rationale") {
      this.showWarningBox(permissionRequestStatus);
    }
  }

  /**
   * This function makes the `<permission-onboarding></permission-onboarding>` permission onboarding element visble.
   */
  private openPermissionOnboardingDialog(): void {
    this.overwriteAndroidBackClickBehavior();
    this.isModalOpen = true;
  }

  /**
   * This function adds a Capacitor-specific `"backButton"` event listener that is fired by Capacitor.
   * By adding a custom `"backButton"` event listener the back button click event is not forwarded to the web view within the app.
   * As a result, back button clicks do not result in a popstate event within the web view and the current page under the modal dialog will not be changed.
   * This behaviour is only required on Android since Android devices do have a physical back button and iOS devices don't.
   */
  private async overwriteAndroidBackClickBehavior() {
    if (Capacitor.getPlatform() === "android") {
      this.appComponent.overwriteDefaultBackButtonListener(() => {
        this.alertService.presentToast(this.translateService.instant("PERMISSION_ONBOARDING_AND_DIARY_SENSOR_INFO.BACK_CLICK_WARNING"));
      });
    }
  }

  /**
   * This function makes the `<permission-onboarding></permission-onboarding>` permission onboarding element invisble and also resolves the `Promise` that was previously returned.
   */
  private closePermissionDialogAndResolveRequest(): void {
    if (this.resolvePermissionRequest) {
      this.resolvePermissionRequest(
        {
          notification: this.notificationPermissionGranted,
          pedometer: this.pedometerPermissionGranted,
          coarseLocation: this.coarseLocationPermissionGranted,
          fineLocation: this.fineLocationPermissionGranted,
          storage: this.storagePermissionGranted
        }
      )
    }
    this.setPermissionDialogInitState();
  }

  /**
   * This function tries to request a certain permission from the user.
   * This function has no effect if the permission has either the state `denied` or `granted`.
   * @param permission The permission (`"location"`,`"pedometer"`,`"notification"`) that must be requested from the user.
   * @returns A `Promise` including the entire permission status of all relevant permissions that resolves when the permission request system dialog has disappeared after the user action.
   */
  public async requestSinglePermission(permission: Permission): Promise<BooleanPermissionStatus> {
    this.permissionsToBeRequested = [permission];
    this.updateDialogContent();
    this.openPermissionOnboardingDialog();
    return new Promise(resolve => { this.resolvePermissionRequest = resolve });
  }

  /**
   * This function requests the current permission in the permission list from the user by using the system permission dialog.
   * The result of the permission request of the current permission is retured within a `Promise`.
   * The function also updates the permission status attributes after each permission request.
   * @returns A `Promise` containing the status of the current permission that resolves after the permission system dialog disappeard.
   */
  private async requestCurrentPermissionOnSystem(): Promise<PermissionStatusValue> {
    this.permissionAlreadyRequested = true;

    switch (this.permissionsToBeRequested[this.permissionListIndex]) {
      case "notification": {
        const notificationPermissionStatus = (await LocalNotifications.requestPermissions()).display;
        this.notificationPermissionGranted = notificationPermissionStatus === "granted";
        return notificationPermissionStatus;
      }

      case "pedometer": {
        const pedometerPermissionStatus = (await SensingService.requestPermissions({ permissions: ["activityRecognition"] })).activityRecognition;
        this.pedometerPermissionGranted = pedometerPermissionStatus === "granted";
        return pedometerPermissionStatus;
      }

      case "location": {
        const locationPermissionStatus = (await SensingService.requestPermissions({ permissions: ["fineLocation"] }));

        // The location permission can be considered as granted if the fineLocation or the coarse location was granted.
        if (locationPermissionStatus.fineLocation === "granted") {
          this.fineLocationPermissionGranted = true;
          this.coarseLocationPermissionGranted = true;
          return "granted"
        }

        if (locationPermissionStatus.coarseLocation === "granted") {
          this.fineLocationPermissionGranted = false;
          this.coarseLocationPermissionGranted = true;
          return "granted"
        }

        return locationPermissionStatus.fineLocation
      }

      case "storage": {
        const storagePermissionStatus = (await Filesystem.requestPermissions()).publicStorage;
        this.storagePermissionGranted = storagePermissionStatus === "granted";
        return storagePermissionStatus;
      }
    }
  }

  /**
   * This function updates the text content of the onboarding dialog according to the current permission in the permission list.
   */
  private updateDialogContent(): void {
    const currentPermissionInList = this.permissionsToBeRequested[this.permissionListIndex];

    switch (currentPermissionInList) {
      case "notification": {
        this.currentIcon = "notifications";
        this.currentPermissionTitle = this.translateService.instant("PERMISSION_ONBOARDING_AND_DIARY_SENSOR_INFO.ONBOARDING_NOTIFICATION_TITLE");
        this.currentPermissionText = this.translateService.instant("PERMISSION_ONBOARDING_AND_DIARY_SENSOR_INFO.ONBOARDING_NOTIFICATION_BODY");
        break;
      }

      case "pedometer": {
        this.currentIcon = "footsteps";
        this.currentPermissionTitle = this.translateService.instant("PERMISSION_ONBOARDING_AND_DIARY_SENSOR_INFO.ONBOARDING_PEDOMETER_TITLE");
        this.currentPermissionText = this.translateService.instant("PERMISSION_ONBOARDING_AND_DIARY_SENSOR_INFO.ONBOARDING_PEDOMETER_BODY") + " " + this.translateService.instant("PERMISSION_ONBOARDING_AND_DIARY_SENSOR_INFO.PEDOMETER_DATA_COLLECTION_REASON");
        break;
      }

      case "location": {
        this.currentIcon = "location";
        this.currentPermissionTitle = this.translateService.instant("PERMISSION_ONBOARDING_AND_DIARY_SENSOR_INFO.ONBOARDING_LOCATION_TITLE");
        this.currentPermissionText = this.translateService.instant("PERMISSION_ONBOARDING_AND_DIARY_SENSOR_INFO.ONBOARDING_LOCATION_BODY") + " " + this.translateService.instant("PERMISSION_ONBOARDING_AND_DIARY_SENSOR_INFO.LOCATION_DATA_COLLECTION_REASON");
        break;
      }
      
      case "storage": {
        this.currentIcon = "save";
        this.currentPermissionTitle = this.translateService.instant("PERMISSION_ONBOARDING_AND_DIARY_SENSOR_INFO.ONBOARDING_STORAGE_TITLE");
        this.currentPermissionText = this.translateService.instant("PERMISSION_ONBOARDING_AND_DIARY_SENSOR_INFO.ONBOARDING_STORAGE_BODY");
        break;
      }
    }
  }

  /**
   * This function shows makes the red warning box visible.
   * @param permissionStatus 
   */
  private showWarningBox(permissionStatus: PermissionStatusValue) {
    this.permissionDeniedTitle = this.translateService.instant("PERMISSION_ONBOARDING_AND_DIARY_SENSOR_INFO.ONBOARDING_PERMISSION_DENIED_TITLE");
    switch (permissionStatus) {
      case "denied": {
        this.warningBoxVisible = true;
        this.retryPermissionRequestButtonVisible = false;
        this.permissionDeniedText = this.translateService.instant("PERMISSION_ONBOARDING_AND_DIARY_SENSOR_INFO.ONBOARDING_PERMISSION_FINAL_DENIED_BODY");
        break;
      }

      case "prompt-with-rationale": {
        this.warningBoxVisible = true;
        this.retryPermissionRequestButtonVisible = true;
        this.permissionDeniedText = this.translateService.instant("PERMISSION_ONBOARDING_AND_DIARY_SENSOR_INFO.ONBOARDING_PERMISSION_DENIED_BODY");
        break;
      }
    }
  }

  /**
   * This function removes the red warning box which appears if the current location in the list was previously not granted.
   */
  private removeWarningBox(): void {
    this.warningBoxVisible = false;
    this.retryPermissionRequestButtonVisible = false;
  }

  /**
   * This function sets the entire instance to the initial state.
   */
  private setPermissionDialogInitState(): void {
    this.isModalOpen = false;
    this.permissionListIndex = 0;
    this.permissionsToBeRequested = [];
    this.currentIcon = "";
    this.currentPermissionTitle = "";
    this.currentPermissionText = "";
    this.permissionDeniedTitle = ""
    this.permissionDeniedText = "";
    this.warningBoxVisible = false;
    this.permissionAlreadyRequested = false;
    this.retryPermissionRequestButtonVisible = false;
    this.notificationPermissionGranted = false;
    this.pedometerPermissionGranted = false;
    this.coarseLocationPermissionGranted = false;
    this.fineLocationPermissionGranted = false;
    this.resolvePermissionRequest = (_) => { };
    this.appComponent.applyDefaultBackButtonListener();
  }

  /**
   * This function returns a detailed status for the permissions `notification`, `pedometer`, `coarseLocation`, and `fineLocation` each containing exact one value of ``, ``, ``, and ``.
   * @returns 
   */
  public async getPermissionStatus(): Promise<DetailedPermissionStatus> {
    const notificationPermissionStatus = await LocalNotifications.checkPermissions();
    const sensingPermissionStatus = await SensingService.checkPermissions({ permissions: ["activityRecognition", "coarseLocation", "fineLocation"] });

    // This line is only required for Android devices. On iOS devices, the storage permission is always granted.
    const storagePermissionStatus = Capacitor.getPlatform() == "android" ? (await Filesystem.checkPermissions()).publicStorage : "granted";

    return {
      notification: notificationPermissionStatus.display,
      pedometer: sensingPermissionStatus.activityRecognition,
      coarseLocation: sensingPermissionStatus.coarseLocation,
      fineLocation: sensingPermissionStatus.fineLocation,
      storage: storagePermissionStatus
    }
  }

  /**
   * Retrieves the accuracy level of the location permission.
   * 
   * Checks the permissions for both "fineLocation" and "coarseLocation" and returns the granted permission accuracy.
   * 
   * @returns A promise that resolves to a `LocationPermissionAccuracy` ("fine" or "coarse") if permission is granted, otherwise `null`.
   */
  public async getLocationPermissionAccuracy(): Promise<LocationPermissionAccuracy | null> {
    const locationPermissionStatus = await SensingService.checkPermissions({ permissions: ["fineLocation","coarseLocation"] });

    if (locationPermissionStatus.fineLocation === "granted") {
      return "fine";
    }

    if (locationPermissionStatus.coarseLocation === "granted") {
      return "coarse";
    }

    return null;
  }

  /**
   * This method checks the status of permissions and returns an array
   * of permissions that have been granted.
   * 
   * @returns A promise that resolves to an array of granted permissions.
   * The array contains the permission types that have been granted, such as "location"
   * and "pedometer".
   * 
   */
  public async getGrantedPermissions(): Promise<Permission[]> {
    const grantedSensorPermissions: Permission[] = [];
    const permissionStatus = await this.getPermissionStatus();

    if (permissionStatus.fineLocation === "granted" || permissionStatus.coarseLocation === "granted") {
      grantedSensorPermissions.push("location");
    }

    if (permissionStatus.pedometer === "granted") {
      grantedSensorPermissions.push("pedometer");
    }

    if (permissionStatus.notification === "granted") {
      grantedSensorPermissions.push("notification");
    }

    if (permissionStatus.storage === "granted") {
      grantedSensorPermissions.push("storage");
    }

    return grantedSensorPermissions;
  }
}

/**
 * Represents the accuracy level of location permission.
 * 
 * - `"fine"`: Indicates that fine location permission has been granted.
 * - `"coarse"`: Indicates that coarse location permission has been granted.
 */
export type LocationPermissionAccuracy = "fine" | "coarse";

/**
 * This enumeration type represents the detailed state of a permission.
 * 
 * `"granted"` -  Every permission in this alias has been granted by the end user (or prompting is not necessary).
 * 
 * `"denied"` - One or more permissions in this alias have been denied by the end user.
 * The permission in this state can only be granted in the system settings of this app.
 * A permission grant system dialog cannot displayed anymore.
 * 
 * `"prompt` - The end user should be prompted for permission, because it has neither been granted nor denied.
 * 
 * `"prompt-with-rationale"`- The end user has denied permission before, but has not blocked the prompt yet **(only Android!)**.
 */
export type PermissionStatusValue = "prompt" | "prompt-with-rationale" | "granted" | "denied";

/**
 * This enumeration type defines alias strings for the notification, pedometer and location permission.
 */
export type Permission = "notification" | "pedometer" | "location" | "storage";

/**
 * Defines the options for requesting required permissions in the app.
 *
 * This interface is used to specify the permissions that may be needed, either for multiple diary instances or individual permissions.
 *
 */
type RequiredPermissionOptions = {
  diaryInstances?: DiaryInstance[];
  singlePermissions?: Permission[];
}

/**
 * This result type provides information about the granted permissions.
 */
type DetailedPermissionStatus = {
  /** - `"granted"` - The notification permission has been granted. 
   * - `"prompt"` - The notification permission has never been requested in the past. It's possible to request the permission.
   * - `"prompt-with-rationale"` - The notification permission has been requested, but was denied by the user. It's possible to request the permission again.
   * - `"denied"` - The notification permission has been denied by the user. It's not possible to request the permission again.
   */
  notification: PermissionStatusValue;

  /** - `"granted"` - The pedometer permission has been granted. 
   * - `"prompt"` - The pedometer permission has never been requested in the past. It's possible to request the permission.
   * - `"prompt-with-rationale"` - The pedometer permission has been requested, but was denied by the user. It's possible to request the permission again.
   * - `"denied"` - The pedometer permission has been denied by the user. It's not possible to request the permission again.
   */
  pedometer: PermissionStatusValue;

  /** - `"granted"` - The fine location permission has been granted. 
   * - `"prompt"` - The fine location permission has never been requested in the past. It's possible to request the permission.
   * - `"prompt-with-rationale"` - The fine location permission has been requested, but was denied by the user. It's possible to request the permission again.
   * - `"denied"` - The fine location permission has been denied by the user. It's not possible to request the permission again.
   */
  fineLocation: PermissionStatusValue;

  /** - `"granted"` - The coarse location permission has been granted. 
   * - `"prompt"` - The coarse location permission has never been requested in the past. It's possible to request the permission.
   * - `"prompt-with-rationale"` - The coarse location permission has been requested, but was denied by the user. It's possible to request the permission again.
   * - `"denied"` - The coarse location permission has been denied by the user. It's not possible to request the permission again.
   */
  coarseLocation: PermissionStatusValue;

  /** - `"granted"` - The storage permission has been granted. 
   * - `"prompt"` - The storage permission has never been requested in the past. It's possible to request the permission.
   * - `"prompt-with-rationale"` - The storage permission has been requested, but was denied by the user. It's possible to request the permission again.
   * - `"denied"` - The storage permission has been denied by the user. It's not possible to request the permission again.
   */
  storage: PermissionStatusValue;
}

/**
 * This result type provides information about the granted permissions.
 */
type BooleanPermissionStatus = {
  /** `true` if the notification permission has been granted. */
  notification: boolean;

  /** `true` if the pedometer permission has been granted. */
  pedometer: boolean;

  /** `true` if the precise location permission has been granted. */
  fineLocation: boolean;

  /** `true` if the coarse location permission has been granted. Also `true` if `fineLocation` is true.*/
  coarseLocation: boolean;

  /** `true` if the storage permission has been granted. */
  storage: boolean;
}
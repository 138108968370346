import { Weekday } from "@capacitor/local-notifications";

export enum QuestionTypes {
  YES_NO,
  SINGLE_CHOICE,
  MULTIPLE_CHOICE,
  SAM_SCALE_FACE,
  SAM_SCALE_BODY,
  SLIDER,
  TEXT_AREA,
  TEXT_STRING,
  TEXT_DATE,
  HEADLINE,
  TEXT,
  MEDIA,
  BLOCK_OPENS,
  BLOCK_CLOSES,
  PAGE_CONDITIONAL,
  SPACE,
  LINE,
}

export enum MediaType {
  IMAGE_PNG,
  IMAGE_JPEG,
  IMAGE_GIF,
  IMAGE_SVG,
  AUDIO_MPEG,
  AUDIO_WAV,
  AUDIO_M4A,
  VIDEO_MP4,
  APPLICATION_DOC,
  APPLICATION_DOCX,
  APPLICATION_PPT,
  APPLICATION_PPTX,
  APPLICATION_ODT,
  APPLICATION_PDF,
  WEB_VTT,
  NOT_SUPPORTED,
}

export namespace MediaType {
  export function isApplication(media: MediaType): boolean {
    return (
      media === MediaType.APPLICATION_DOC ||
      media === MediaType.APPLICATION_DOCX ||
      media === MediaType.APPLICATION_PPT ||
      media === MediaType.APPLICATION_PPTX ||
      media === MediaType.APPLICATION_ODT ||
      media === MediaType.APPLICATION_PDF
    );
  }

  export function isAudio(media: MediaType): boolean {
    return (
      media === MediaType.AUDIO_MPEG ||
      media === MediaType.AUDIO_WAV ||
      media === MediaType.AUDIO_M4A
    );
  }

  export function isImage(media: MediaType): boolean {
    return (
      media === MediaType.IMAGE_PNG ||
      media === MediaType.IMAGE_JPEG ||
      media === MediaType.IMAGE_GIF ||
      media === MediaType.IMAGE_SVG
    );
  }

  export function isVideo(media: MediaType): boolean {
    return media === MediaType.VIDEO_MP4;
  }

  export function isWord(media: MediaType): boolean {
    return (
      media === MediaType.APPLICATION_DOC ||
      media === MediaType.APPLICATION_DOCX
    );
  }

  export function isExcel(media: MediaType): boolean {
    return media === MediaType.APPLICATION_ODT;
  }

  export function isPowerpoint(media: MediaType): boolean {
    return (
      media === MediaType.APPLICATION_PPT ||
      media === MediaType.APPLICATION_PPTX
    );
  }

  export function isPdf(media: MediaType): boolean {
    return media === MediaType.APPLICATION_PDF;
  }

  export function isWebVTT(media: MediaType): boolean {
    return media === MediaType.WEB_VTT;
  }
}

export enum TextType {
  NONE,
  IMPORTANT,
  TIP,
  INFO,
  SUCCESS,
  HIGHLIGHT,
  TABLE,
}
export enum BlockType {
  NONE,
  DETAILS,
  CONDITIONAL,
  BOTH,
}

export enum NotificationType {
  NORMAL,
  REMINDER,
}

export enum NotificationInterval {
  ONCE,
  DAILY,
  WEEKLY,
  MONTHLY,
}

export namespace NotificationInterval {
  export function parseString(
    intervalString: string | undefined,
  ): NotificationInterval | undefined {
    if (intervalString === undefined) {
      return undefined;
    }

    switch (intervalString.toLowerCase()) {
      case "once":
        return NotificationInterval.ONCE;
      case "daily":
        return NotificationInterval.DAILY;
      case "weekly":
        return NotificationInterval.WEEKLY;
      case "monthly":
        return NotificationInterval.MONTHLY;
      default: {
        console.error(`Unknown NotificationInterval: ${intervalString}`);
        return undefined;
      }
    }
  }
}

export enum DayOfWeek {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

export namespace DayOfWeek {
  export function parseString(dayOfWeekString: string): DayOfWeek | undefined {
    if (dayOfWeekString === undefined) {
      return undefined;
    }

    switch (dayOfWeekString.toLowerCase()) {
      case "monday":
        return DayOfWeek.MONDAY;
      case "tuesday":
        return DayOfWeek.TUESDAY;
      case "wednesday":
        return DayOfWeek.WEDNESDAY;
      case "thursday":
        return DayOfWeek.THURSDAY;
      case "friday":
        return DayOfWeek.FRIDAY;
      case "saturday":
        return DayOfWeek.SATURDAY;
      case "sunday":
        return DayOfWeek.SUNDAY;
      default: {
        console.error(`Unknown DayOfWeek: ${dayOfWeekString}`);
        return undefined;
      }
    }
  }

  export function convertToLocalNotificationWeekday(
    dayOfWeek: DayOfWeek,
  ): Weekday {
    switch (dayOfWeek) {
      case DayOfWeek.MONDAY:
        return Weekday.Monday;
      case DayOfWeek.TUESDAY:
        return Weekday.Tuesday;
      case DayOfWeek.WEDNESDAY:
        return Weekday.Wednesday;
      case DayOfWeek.THURSDAY:
        return Weekday.Thursday;
      case DayOfWeek.FRIDAY:
        return Weekday.Friday;
      case DayOfWeek.SATURDAY:
        return Weekday.Saturday;
      case DayOfWeek.SUNDAY:
        return Weekday.Sunday;
    }
  }
}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { WebAppGuard } from './guards/webapp.guard';
import { SkillFeatureGuard } from './guards/skill-feature.guard';

const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingPageModule), canActivate: [WebAppGuard] },
  { path: 'landing', loadChildren: () => import('./pages/landing/landing.module').then(m => m.LandingPageModule), canActivate: [WebAppGuard] },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule), canActivate: [WebAppGuard] },
  { path: 'forgot-password', loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule), canActivate: [WebAppGuard] },
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule), canActivate: [AuthGuard, WebAppGuard] },
  { path: 'intervention-overview', loadChildren: () => import('./pages/intervention-overview/intervention-overview.module').then(m => m.InterventionOverviewPageModule), canActivate: [AuthGuard] },
  { path: 'lesson-overview/:interventionID', loadChildren: () => import('./pages/lesson-overview/lesson-overview.module').then(m => m.LessonOverviewPageModule), canActivate: [AuthGuard] },
  { path: 'lesson-overview/:interventionID/:instanceID', loadChildren: () => import('./pages/lesson-overview/lesson-overview.module').then(m => m.LessonOverviewPageModule), canActivate: [AuthGuard] },
  { path: 'lesson/:lessonID', loadChildren: () => import('./pages/lesson/lesson.module').then(m => m.LessonPageModule), canActivate: [AuthGuard] }, // has canDeactivate in lesson.module (not used for answersheet route below) -> todo move it here  
  { path: 'lesson/:lessonID/:instanceID', loadChildren: () => import('./pages/lesson/lesson.module').then(m => m.LessonPageModule), canActivate: [AuthGuard] }, // has canDeactivate in lesson.module (not used for answersheet route below) -> todo move it here  
  { path: 'lesson/:lessonID/:instanceID/:answersheetID', loadChildren: () => import('./pages/lesson/lesson.module').then(m => m.LessonPageModule), canActivate: [AuthGuard] },
  { path: 'diary-overview', loadChildren: () => import('./pages/diary-overview/diary-overview.module').then(m => m.DiaryOverviewPageModule), canActivate: [AuthGuard, WebAppGuard] },
  { path: 'diary/:diaryID/:instanceID', loadChildren: () => import('./pages/diary/diary.module').then(m => m.DiaryPageModule), canActivate: [AuthGuard, WebAppGuard] },
  { path: 'feedback-overview', loadChildren: () => import('./pages/feedback-overview/feedback-overview.module').then(m => m.FeedbackOverviewPageModule), canActivate: [AuthGuard, WebAppGuard] },
  { path: 'chat/:threadID', loadChildren: () => import('./pages/chat/chat.module').then(m => m.ChatPageModule), canActivate: [AuthGuard, WebAppGuard] },
  { path: 'invitations', loadChildren: () => import('./pages/invitations/invitations.module').then(m => m.InvitationsPageModule), canActivate: [AuthGuard] },
  { path: 'settings', loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule), canActivate: [AuthGuard, WebAppGuard] },
  { path: 'imprint', loadChildren: () => import('./pages/imprint/imprint.module').then(m => m.ImprintPageModule), canActivate: [AuthGuard, WebAppGuard] },
  { path: 'app-imprint', loadChildren: () => import('./pages/imprint/imprint.module').then(m => m.ImprintPageModule), canActivate: [WebAppGuard] }, //imprint without auth (no menu & navbar, other background)
  { path: 'privacy', loadChildren: () => import('./pages/privacy/privacy.module').then(m => m.PrivacyPageModule), canActivate: [AuthGuard, WebAppGuard] },
  { path: 'app-privacy', loadChildren: () => import('./pages/privacy/privacy.module').then(m => m.PrivacyPageModule), canActivate: [WebAppGuard] }, //privacy without auth (no menu & navbar & more preferences (account deletion), other background)
  { path: 'security', loadChildren: () => import('./pages/security/security.module').then(m => m.SecurityPageModule), canActivate: [AuthGuard, WebAppGuard] },
  { path: 'skill-overview/:interventionID/:instanceID', loadChildren: () => import('./pages/skill-overview/skill-overview.module').then(m => m.SkillOverviewPageModule), canActivate: [AuthGuard, WebAppGuard, SkillFeatureGuard] },
  { path: 'skill/:interventionID/:instanceID/:skillID', loadChildren: () => import('./pages/skill/skill.module').then(m => m.SkillPageModule), canActivate: [AuthGuard, WebAppGuard, SkillFeatureGuard] },
  { path: 'progress', loadChildren: () => import('./pages/progress/progress.module').then(m => m.ProgressPageModule), canActivate: [AuthGuard, WebAppGuard, SkillFeatureGuard] },
  { path: '**', redirectTo: 'home', pathMatch: 'full' }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule]
})
// @ts-ignore
export class AppRoutingModule { }

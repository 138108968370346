import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { HelperService } from "../../providers/helper/helper.service";
import { Answer } from "../../models/Answer";
import { NotificationIDMapping } from 'src/app/models/NotificationIDMapping';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  constructor(
    private nativeStorage: Storage,
    private helperService: HelperService,
  ) {
    this.init();
  }

  async init() {
    this.nativeStorage = await this.nativeStorage.create();
    await this.removeLegacyData();

    // if the browser has a valid token but not a stored AES key, initialize the AES key for the user
    const userId = this.getUserId();
    await this.initAESKeyForUser(userId);
  }

  public setToken(token: string) {
    localStorage.setItem('token', token);
  }

  public getToken(): string {
    let token = localStorage.getItem('token');
    if (!token) {
      token = "";
    }
    return token
  }

  public removeToken() {
    localStorage.removeItem('token');
  }

  // Set by native app using In-App-Browser
  public getIsInAppBrowser(): boolean {
    return localStorage.getItem("mobile_app") === "1";
  }

  // Set by native app using In-App-Browser
  public hideHeaderBackButton(): boolean {
    return localStorage.getItem("hide_header_back_button") === "1";
  }

  public setUserId(id: number) {
    localStorage.setItem('userid', String(id));
  }

  public getUserId(): number {
    let id = parseInt(localStorage.getItem('userid'));
    if (!id) {
      id = -1;
    }
    return id
  }

  public removeUserId() {
    localStorage.removeItem('userid');
  }

  public setUserEmail(email: string) {
    localStorage.setItem('userMail', email);
  }

  public getUserEmail(): string {
    let email = localStorage.getItem('userMail');
    if (!email) {
      email = "";
    }
    return email
  }

  public removeUserEmail() {
    localStorage.removeItem('userMail');
  }

  public setTheme(theme: string) {
    localStorage.setItem('theme', theme);
  }

  public getTheme(): string {
    let theme = localStorage.getItem('theme');
    if (!theme) {
      theme = "0";
    }
    return theme
  }

  public setBackground(background: string) {
    localStorage.setItem('background', background);
  }

  public getBackground(): string {
    let background = localStorage.getItem('background');
    if (!background) {
      background = "0";
    }
    return background
  }

  public setAppLanguage(language: string) {
    localStorage.setItem('appLanguage', language);
  }

  public getAppLanguage() {
    let language = localStorage.getItem('appLanguage');
    if (!this.helperService.isValidLanguage(language)) {
      language = this.helperService.getBrowserOrDefaultLanguage();
      this.setAppLanguage(language);
    }
    return language;
  }

  public setDeviceTokenId(id: string) {
    localStorage.setItem('devicetoken', String(id));
  }

  public getDeviceTokenId(): number {
    let id = parseInt(localStorage.getItem('devicetoken'));
    if (!id) {
      id = -1;
    }
    return id
  }

  public removeDeviceTokenId() {
    localStorage.removeItem('devicetoken');
  }

  /**
   * Sets quicksaved answers for a specific lesson instance in the IndexedDB.
   * 
   * @param lessonId - The ID of the lesson for which quicksaved answers are being set.
   * @param instanceId - The ID of the instance of the lesson for which quicksaved answers are being set.
   * @param isDiary - A boolean indicating whether the quicksaved answers belong to a diary entry.
   * @param answers - An array of quicksaved answers.
   * @param page - The page number associated with the quicksaved answers.
   * @param savedAt - Timestamp of saving.
   * @param answersheetId - ID of the corresponding answersheet.
   * @returns A Promise that resolves once the quicksaved answers have been stored.
   */
  public async setQuicksavedAnswers(lessonId: number, instanceId: number, isDiary: boolean, answers: Array<Answer>, page: number, savedAt: number, answersheetId?: number) {
    const data = {
      answers,
      page,
      answersheetId,
      savedAt,
    };
    const dbKey = `lesson${lessonId}:quicksavedAnswers${instanceId}:instance${isDiary}isDiary`;
    const iv = crypto.getRandomValues(new Uint8Array(16));
    await this.setIV(dbKey, iv);
    const AESKey = await this.getAESKey(this.getUserId());
    const encryptedData = await this.encrypt(JSON.stringify(data), AESKey, iv);
    return this.setHashed(dbKey, encryptedData);
  }

  /**
   * Retrieves quicksaved answers associated with a specific lesson instance from the IndexedDB.
   * 
   * @param lessonId - The ID of the lesson for which quicksaved answers are being retrieved.
   * @param instanceId - The ID of the instance of the lesson for which quicksaved answers are being retrieved.
   * @param isDiary - A boolean indicating whether the quicksaved answers belong to a diary entry.
   * @returns A Promise that resolves with the quicksaved answers data.
   */
  public async getQuicksavedAnswers(lessonId: number, instanceId: number, isDiary: boolean): Promise<any> {
    const dbKey = `lesson${lessonId}:quicksavedAnswers${instanceId}:instance${isDiary}isDiary`;
    return this.getHashed(dbKey)
      .then(async (cipherText) => {
        const iv = await this.getIV(dbKey);
        const AESKey = await this.getAESKey(this.getUserId());
        const decryptedData = await this.decrypt(cipherText, AESKey, iv);
        return JSON.parse(decryptedData);
      })
      .catch(() => { return []; });
  }

  /**
   * Removes quicksaved answers associated with a specific lesson instance from the IndexedDB.
   * 
   * @param lessonId - The ID of the lesson for which quicksaved answers are being removed.
   * @param instanceId - The ID of the instance of the lesson for which quicksaved answers are being removed.
   * @param isDiary - A boolean indicating whether the quicksaved answers belong to a diary entry.
   * @returns A Promise that resolves once the quicksaved answers have been removed.
   */
  public async removeQuicksavedAnswers(lessonId: number, instanceId: number, isDiary: boolean) {
    const dbKey = `lesson${lessonId}:quicksavedAnswers${instanceId}:instance${isDiary}isDiary`;
    await this.removeIV(dbKey)
    return new Promise((resolve, reject) => {
      try {
        this.removeHashed(dbKey).then((data) => {
          resolve(data);
        }, (error) => {
          reject(error);
        });
      } catch (exception) {
        reject(exception);
      }
    });
  }

  /**
   * Sets the progress data for a specific lesson instance in the IndexedDB.
   * 
   * @param lessonId - The ID of the lesson for which progress is being set.
   * @param instanceId - The ID of the instance of the lesson progress being set.
   * @param currentPage - The current page of the lesson instance.
   * @param totalPages - The total number of pages in the lesson instance.
   * @returns A Promise that resolves once the progress data has been stored.
   */
  public async setLessonProgress(lessonId: number, instanceId: number, currentPage: number, totalPages: number) {
    let savedDate = new Date();
    let data = {
      currentPage: currentPage,
      totalPages: totalPages,
      savedDate: savedDate
    }
    const dbKey = `lesson${lessonId}:progress${instanceId}:instance`;
    const iv = crypto.getRandomValues(new Uint8Array(16));
    await this.setIV(dbKey, iv);
    const AESKey = await this.getAESKey(this.getUserId());
    let encryptedData = await this.encrypt(JSON.stringify(data), AESKey, iv);
    return this.setHashed(dbKey, encryptedData);
  }

  /**
   * Retrieves the progress of a specific lesson instance from the IndexedDB.
   * 
   * @param lessonId - The ID of the lesson whose progress is being retrieved.
   * @param instanceId - The ID of the instance of the lesson progress being retrieved.
   * @returns A Promise that resolves with the progress data of the lesson instance.
   */
  public async getLessonProgress(lessonId: number, instanceId: number): Promise<any> {
    const dbKey = `lesson${lessonId}:progress${instanceId}:instance`;
    const iv = await this.getIV(dbKey);
    const AESKey = await this.getAESKey(this.getUserId());
    return await this.getHashed(dbKey).then(async (cipherText) => {
      const decryptedData = await this.decrypt(cipherText, AESKey, iv);
      return JSON.parse(decryptedData);
    }).catch((error) => {
      return [];
    });
  }

  /**
   * Removes the lesson progress associated with a specific lesson and instance from the IndexedDB.
   * 
   * @param lessonId - The ID of the lesson whose progress is being removed.
   * @param instanceId - The ID of the instance of the lesson progress being removed.
   * @returns A Promise that resolves once the lesson progress has been removed.
   */
  public async removeLessonProgress(lessonId: number, instanceId: number) {
    const dbKey = `lesson${lessonId}:progress${instanceId}:instance`;
    this.removeIV(dbKey);
    return new Promise((resolve, reject) => {
      try {
        this.removeHashed(dbKey).then((data) => {
          resolve(data);
        }, (error) => {
          reject(error);
        });
      } catch (exception) {
        reject(exception);
      }
    });
  }

  /**
   * Stores a key-value pair in the IndexedDB. The value of the key will be hashed before it is stored in the IndexedDB.
   * 
   * @param key - The key to be hashed and stored.
   * @param value - The value to be stored.
   * @returns A Promise that resolves once the hashed key-value pair has been stored.
   */
  private async setHashed(key: string, value: any): Promise<any> {
    const hashedDBKey = await this.hashKey(key);
    return this.nativeStorage.set(hashedDBKey, value);
  }

  /**
   * Retrieves the value associated with a hashed key from the IndexedDB.
   * 
   * @param key - The key whose value is to be retrieved.
   * @returns A Promise that resolves with the value associated with the key.
   */
  private async getHashed(key: string): Promise<any> {
    const hashedDBKey = await this.hashKey(key);
    return this.nativeStorage.get(hashedDBKey);
  }

  /**
   * Removes the hashed key-value pair associated with a specified key from the IndexedDB.
   * 
   * @param key - The key whose hashed key-value pair is to be removed.
   * @returns A Promise that resolves once the hashed key-value pair has been removed.
   */
  private async removeHashed(key: string): Promise<any> {
    const hashedDBKey = await this.hashKey(key);
    return this.nativeStorage.remove(hashedDBKey);
  }

  /**
   * Sets the initialization vector (IV) for a specified key.
   * This function stores the initialization vector (IV) used for AES-GCM decryption in the IndexedDB.
   * 
   * @param key - The key identifier for which the IV is being set.
   * @param iv - The initialization vector (IV) to be set, provided as a Uint8Array.
   * @returns A Promise that resolves once the IV has been set.
   */
  private async setIV(key: string, iv: Uint8Array): Promise<any> {
    return this.setHashed(`${key}_enc_iv`, iv);
  }

  /**
   * Retrieves the initialization vector (IV) associated with a specified key.
   * This function retrieves the initialization vector (IV) used for AES-GCM decryption from the IndexedDB.
   * 
   * @param key - The key identifier for which the IV is being retrieved.
   * @returns A Promise that resolves with the initialization vector (IV) as a Uint8Array.
   */
  private async getIV(key: string): Promise<Uint8Array> {
    return this.getHashed(`${key}_enc_iv`);
  }

  /**
   * Removes the initialization vector (IV) associated with a specified key from the IndexedDB.
   * 
   * @param key - The key identifier for which the IV is being removed.
   * @returns A Promise that resolves once the IV has been removed.
   */
  private async removeIV(key: string): Promise<any> {
    return this.removeHashed(`${key}_enc_iv`);
  }

  /**
   * Sets the AES key for the specified user ID.
   * 
   * @param userId - The ID of the user for whom the AES key is being set.
   * @param key - The AES key to be set, provided as a CryptoKey object.
   * @returns A Promise that resolves once the AES key has been set.
   */
  private async setAESKey(userId: number, key: CryptoKey): Promise<any> {
    return this.setHashed(`aes_key_${userId}`, key);
  }

  /**
   * Retrieves the AES key associated with the specified user ID.
   * 
   * @param userId - The ID of the user whose AES key is being retrieved.
   * @returns A Promise that resolves with the AES key as a CryptoKey object.
   */
  private async getAESKey(userId: number): Promise<CryptoKey> {
    return this.getHashed(`aes_key_${userId}`);
  }

  /**
   * Hashes a DB key using the SHA-256 algorithm and returns the hash value as a hexadecimal string.
   * 
   * @param key - The key to be hashed, provided as a string.
   * @returns A Promise that resolves with the hash value of the key as a hexadecimal SHA-256 string.
   */
  private async hashKey(key: string): Promise<string> {
    // The source code in this function is from
    // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#converting_a_digest_to_a_hex_string
    const msgUint8 = new TextEncoder().encode(key); // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join(""); // convert bytes to hex string
    return hashHex;
  }

  /**
   * Encrypts a clear text using the provided AES key and initialization vector (IV) by using the AES algorithm with GCM mode.
   * 
   * @param clearText - The clear text to be encrypted, provided as a string.
   * @param key - The AES key used for encryption, provided as a CryptoKey object.
   * @param iv - The initialization vector (IV) used for encryption, provided as a Uint8Array.
   * @returns A Promise that resolves with the encrypted cipher text as an ArrayBuffer.
   */
  private async encrypt(clearText: string, key: CryptoKey, iv: Uint8Array): Promise<ArrayBuffer> {
    const encoder = new TextEncoder();
    const encodedClearText = encoder.encode(clearText);

    return crypto.subtle.encrypt(
      {
        "name": "AES-GCM",
        "iv": iv
      },
      key,
      encodedClearText
    );
  }

  /**
   * Decrypts a cipher text using the provided AES key and initialization vector (IV) by using the AES algorithm with GCM mode.
   * 
   * @param cipherText - The cipher text to be decrypted, represented as an ArrayBuffer.
   * @param key - The AES key used for decryption, provided as a CryptoKey object.
   * @param iv - The initialization vector (IV) used for decryption, provided as a Uint8Array.
   * @returns A Promise that resolves with the decrypted clear text as a string.
   */
  private async decrypt(cipherText: ArrayBuffer, key: CryptoKey, iv: Uint8Array): Promise<string> {
    const encodedClearText = await crypto.subtle.decrypt(
      {
        "name": "AES-GCM",
        "iv": iv
      },
      key,
      cipherText
    );

    const decoder = new TextDecoder();
    const decodedClearText = decoder.decode(encodedClearText);

    return decodedClearText
  }

  /**
   * Initializes an AES key for a specified user and stores it in the IndexedDB as `CryptoKey` object with
   * the `extractable` property set to `false`.
   * 
   * @param userId - The ID of the user for whom the AES key is being initialized.
   * @returns A Promise that resolves with no additional information once the AES key has been initialized and stored in the IndexedDB.
   */
  public async initAESKeyForUser(userId: number): Promise<void> {
    const key = await this.getAESKey(userId);

    // generate only a new AES key if the key does not exist and the user ID is provided which corresponds to a logged in user
    if (!key && userId !== -1) {
      console.log("Key generated")
      await crypto.subtle.generateKey(
        {
          name: "AES-GCM",
          length: 256,
        },
        false,
        ["encrypt", "decrypt"],
      ).then((key) => this.setAESKey(userId, key))
    } else {
      console.log("No key generated!")
    }
  }

  /**
   * Removes legacy data from the IndexedDB and local storage.
   * This includes removing lesson progress data and quicksaved answers that were stored using the weak encryption mechanism.
   * In addition, it removes bcrypt-hashed passwords stored in the local storage.
   * 
   * @returns A Promise that resolves with an array of deleted items.
   */
  public async removeLegacyData(): Promise<any[]> {
    const dbKeys = await this.nativeStorage.keys();
    const deletePromises: Promise<any>[] = [];

    const lessonProgressPattern = new RegExp("^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,})?:lesson\\d+:progress\\d+:instance$");
    const quicksavedAnswersPattern = new RegExp("^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,})?:lesson\\d+:quicksavedAnswers\\d+:instance(?:true|false)isDiary$");

    dbKeys.forEach((key) => {
      if (lessonProgressPattern.test(key) || quicksavedAnswersPattern.test(key)) {
        console.log(`${key} is a legacy key. Removing it...`);
        deletePromises.push(this.nativeStorage.remove(key))
      }
    });

    localStorage.removeItem(this.getUserId() + "");

    return Promise.all(deletePromises);
  }


  /**
   * Sets the initialization state of the pedometer in local storage.
   * 
   * @param isInitialized - A boolean indicating whether the pedometer has been initialized.
   */
  public setPedometerInitialized(isInitialized: boolean): void {
    localStorage.setItem("pedometerInitialized",isInitialized ? "1" : "0");
  }

  /**
   * Retrieves the initialization state of the pedometer from local storage.
   * 
   * @returns A boolean indicating whether the pedometer has been initialized.
   */
  public getPedometerInitialized() {
    return localStorage.getItem("pedometerInitialized") === "1";
  }

  /**
   * Adds a notification ID mapping to local storage and updates the list containing all notification IDs.
   *
   * @param notificationID The ID of the notification to map.
   * @param idMapping The mapping information to store.
   */
  public addNotificationIDMapping(notificationID: number, idMapping: NotificationIDMapping) {
    // Add notification ID mapping
    localStorage.setItem(`notification_${notificationID}`, JSON.stringify(idMapping));

    // Add to list containing all notification IDs
    const allNotificationIDsString: string | null = localStorage.getItem("notification_ids");
    const allNotificationIDs: Array<number> = allNotificationIDsString === null ? [] : JSON.parse(allNotificationIDsString);
    if (!allNotificationIDs.includes(notificationID)) {
      allNotificationIDs.push(notificationID);
    }
    localStorage.setItem("notification_ids", JSON.stringify(allNotificationIDs));
  }

  /**
   * Retrieves a notification ID mapping from local storage.
   *
   * @param notificationID The ID of the notification to retrieve the mapping for.
   * @returns The mapping information, or null if not found.
   */
  public getNotificationIDMapping(notificationID: number): NotificationIDMapping | null {
    const idMappingString: string | null = localStorage.getItem(`notification_${notificationID}`)
    if (idMappingString === null) {
      return null;
    }

    const parsedIDMapping = JSON.parse(idMappingString);
    const date: Date | undefined =  parsedIDMapping.date === undefined ? undefined : new Date(parsedIDMapping.date)
    const endDate: Date | undefined = parsedIDMapping.endDate === undefined ? undefined : new Date(parsedIDMapping.endDate);

    const notificationIDMapping: NotificationIDMapping = {
      notificationID: parsedIDMapping.notificationID,
      type: parsedIDMapping.type,
      interval: parsedIDMapping.interval,
      idOfDiaryInstance: parsedIDMapping.idOfDiaryInstance,
      scheduleID: parsedIDMapping.scheduleID,
      date: date,
      endDate: endDate,
      reminderIndex: parsedIDMapping.reminderIndex
    }

    return notificationIDMapping;
  }

  /**
   * Removes a notification ID mapping from local storage.
   *
   * @param notificationID The ID of the notification to remove the mapping for.
   */
  public removeNotificationIDMapping(notificationID: number) {
    localStorage.removeItem(`notification_${notificationID}`);
  }
  
  /**
   * Retrieves all notification IDs from local storage.
   *
   * @returns An array of all notification IDs.
   */
  public getAllNotificationIDs(): Array<number> {
    const allNotificationIDsString: string | null = localStorage.getItem("notification_ids");
    return allNotificationIDsString === null ? [] : JSON.parse(allNotificationIDsString);
  }
  
  /**
   * Removes a notification ID from the list of all notification IDs.
   *
   * @param notificationID The ID of the notification to remove from the list.
   */
  public removeNotificationIDFromAllIDs(notificationID: number) {
    const allNotificationIDsString: string | null = localStorage.getItem("notification_ids");
    const allNotificationIDs: Array<number> = localStorage.getItem("notification_ids") === null ? [] : JSON.parse(allNotificationIDsString);
    
    const index = allNotificationIDs.indexOf(notificationID);
    if (index > -1) {
      allNotificationIDs.splice(index, 1);
    }

    localStorage.setItem("notification_ids", JSON.stringify(allNotificationIDs));
  }

  /**
   * Sets the completion date for a specific diary instance in local storage.
   *
   * @param idOfDiaryInstance - The ID of the diary instance.
   * @param date - The completion date to set.
   */
  public setDiaryInstanceCompletedDate(idOfDiaryInstance: number, date: Date) {
    localStorage.setItem(`diaryInstanceCompletedDate_${idOfDiaryInstance}`, date.getTime().toString());
  }

  /**
   * Retrieves the completion date for a specific diary instance from local storage.
   *
   * @param idOfDiaryInstance - The ID of the diary instance.
   * @returns The completion date as a Date object, or null if not set.
   */
  public getDiaryInstanceCompletedDate(idOfDiaryInstance: number): Date | null {
    const dateString: string | null = localStorage.getItem(`diaryInstanceCompletedDate_${idOfDiaryInstance}`);
    return dateString === null ? null : new Date(parseInt(dateString));
  }

  /**
   * Removes the completion date for a specific diary instance from local storage.
   *
   * This function should only be called when the diary is closed (TBA) / removed and no more notifications should be sent for it, as
   * otherwise some reminders cannot be scheduled correctly.
   * 
   * @param idOfDiaryInstance - The ID of the diary instance.
   */
  public removeDiaryInstanceCompletedDate(idOfDiaryInstance: number) {
    localStorage.removeItem(`diaryInstanceCompletedDate_${idOfDiaryInstance}`);
  }

  /**
   * Generates a unique notification ID.
   * Ensures the ID does not exceed the maximum or minimum allowable values.
   *
   * @returns A unique notification ID.
   */
  public getUniqueNotificationID(): number {
    let uniqueIDString: string | null = localStorage.getItem("uniqueNotificationID");
    let uniqueID: number;

    const LOWEST_POSSIBLE_NOTIFICATION_ID = -2147483647;

    if (uniqueIDString !== null) {
      uniqueID = parseInt(uniqueIDString);
    } else {
      uniqueID = LOWEST_POSSIBLE_NOTIFICATION_ID;
    }

    let nextUniqueID = uniqueID + 1;
    // The highest possible notification ID is 2147483647
    if (nextUniqueID === 2147483648) {
      nextUniqueID =  LOWEST_POSSIBLE_NOTIFICATION_ID;
    }

    localStorage.setItem("uniqueNotificationID", nextUniqueID.toString());
    return uniqueID;
  }
}

import { Component, Input } from "@angular/core";
import { Question } from "../Question";
import { QuestionTextStringModel } from "../../../models/elements/QuestionTextStringModel";
import { Keyboard } from '@capacitor/keyboard';

@Component({
  selector: "app-text-string",
  templateUrl: "./text-string.component.html",
  styleUrls: ["./text-string.component.scss"],
})
export class TextString extends Question {
  @Input() public loopIndex: number;
  public model: QuestionTextStringModel;
  value: string | null = null;

  constructor() {
    super();
  }

  ngAfterViewInit() {
    //emit null (so that there is an entry for each repetition) or saved answer
    this.emitAnswer(this.currentValue);
  }

  ngOnDestroy() {
    console.log("destroy pos " + this.model.position + " loop" + this.loopIndex);
    this.model.lesson.removeAnswerRepetition(this.model.position, this.loopIndex);
  }

  public emitAnswer(value: string | null | undefined) {
    if (value === undefined || this.model.lesson.loading) {
      return;
    }
    this.model.lesson.insertAnswer(this.model.position, this.loopIndex, value);
    this.value = this.currentValue;
  }

  get currentValue(): string | null {
    return this.model.lesson.getAnswer(this.model.position, this.loopIndex);
  }

  closeKeyboard() {
    Keyboard.hide();
  }
}

<div
  [class.required]="model.required"
  class="ion-text-left question questionTable wideTable"
>
  <div *ngFor="let question of questionTexts; let i = index">
    <ion-label
      [class.answerRequired]="model.highlight && currentValue[i] == null"
      class="ion-text-wrap ionLabelText"
      color="page-font"
    >
      {{ question }}
    </ion-label>
    <ion-radio-group [(ngModel)]="value[i]" (ionChange)="emitAnswer()">
      <ion-list>
        <div>
          <ion-item
            *ngFor="let n of [].constructor(numberOfValues); let m = index"
            lines="none"
          >
            <ion-radio
              slot="start"
              labelPlacement="end"
              mode="md"
              [value]="model.minVal + m"
              [disabled]="model.isDisabled"
              [attr.id]="
                'questionTable' +
                model.position +
                '-' +
                loopIndex +
                '-q' +
                i +
                '-a' +
                m
              "
            >
              <span class="ion-text-wrap">{{ model.answerTexts[model.minVal + m] }}</span>
            </ion-radio>
          </ion-item>
        </div>
      </ion-list>
    </ion-radio-group>
  </div>
</div>

import { Injectable, NgZone } from "@angular/core";
import { Router } from "@angular/router";
import { Capacitor } from "@capacitor/core";
import { ActionPerformed, DeliveredNotifications, DeliveredNotificationSchema, LocalNotificationDescriptor, LocalNotifications, LocalNotificationSchema, ScheduleResult } from "@capacitor/local-notifications";
import { TranslateService } from "@ngx-translate/core";
import { catchError, lastValueFrom, map, min, throwError } from "rxjs";
import { RequestProviderService } from "../request-provider/request-provider.service";
import { ParserService } from "../parser/parser.service";
import { LocalStorageService } from "../local-storage/local-storage.service";
import { DiaryInstance } from "src/app/models/DiaryInstance";
import { Schedule } from "src/app/models/Schedule";
import { DayOfWeek, NotificationInterval, NotificationType } from "src/app/models/elements/Enums";
import { NotificationIDMapping } from "src/app/models/NotificationIDMapping";

@Injectable({
  providedIn: "root"
})
export class LocalNotificationService {
  private readonly MAX_NOTIFICATION_COUNT = 60;
  // Flag indicating whether the listeners have already been added in the current lifecycle of the app
  private listenersAdded = false;
  /**
   * ID of the  notification to skip when removing notification info from local storage.
   * 
   * This is necessary because when a delivered notification is clicked, the system automatically removes it from the notification tray
   * As a result `handleNotificationAction` is triggered, and simultaneously the app opens on the home page and runs `scheduleDiaryNotifications`, 
   * removing all reminders that are not currently delivered.
   * 
   * Since the notification is already removed by the system, it no longer appears as a delivered notification and it would be removed from local storage.
   * If `handelNotificationAction` then attempts to retrieve the mapping of the actioned notification, the mapping does not exist,
   * preventing `handleNotificationAction` from opening the corresponding diary entry page.
   */
  private actionedNotificationID: number | null = null;

  constructor(
    private router: Router,
    private requestProvider: RequestProviderService,
    private parserService: ParserService,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    private zone: NgZone
  ) { }

  /**
   * Schedules notifications for all unlocked diary instances, if the app is running on a native platform and has the permission to display notifications.
   * If the app is not running on a native platform, or if notification permissions are not granted, no notifications are scheduled.
   */
  public async scheduleDiaryNotifications() {
    if (
      !Capacitor.isNativePlatform() ||
      (await LocalNotifications.checkPermissions()).display !== "granted"
    ) {
      return;
    }

    this.initializeLocalNotificationListeners();

    const currentDate = new Date();
    let notificationsToCancel: Array<LocalNotificationDescriptor> = [];

    const diaryInstances = await this.getUnlockedDiaryInstances();
    const diaryInstancesWithSchedules = diaryInstances.filter(
      (diaryInstance) => diaryInstance.schedules.length > 0
    );
    let scheduledNotifications = (await LocalNotifications.getPending()).notifications;
    const scheduledNotificationIDs = scheduledNotifications.map((notification) => notification.id);
    const deliveredNotifications = (await this.getDeliveredNotifications()).notifications;
    const deliveredNotificationIDs = deliveredNotifications.map(notification => notification.id);

    let notificationIDMappings = scheduledNotificationIDs // TODO Refactor
      .map((notificationID) => {
        const mapping = this.localStorageService.getNotificationIDMapping(notificationID);

        if (mapping === null) {
          this.removeNotificationIDInfoFromLocalStorage(notificationID, deliveredNotificationIDs);
          notificationsToCancel.push({ id: notificationID });
        }

        return mapping;
      })
      .filter((mapping) => {
        if (mapping === null) {
          return false;
        }

        const correspondingDiaryInstance = diaryInstancesWithSchedules.find((diaryInstance) => diaryInstance.id === mapping.idOfDiaryInstance);
        const correspondingSchedule = correspondingDiaryInstance.schedules.find((schedule) => schedule.scheduleID === mapping.scheduleID);
        const nextNotificationDate = this.getNextNotificationDate(correspondingSchedule, true);

        // Don't filter out the mapping if it belongs to a delivered notification, as it may still be needed
        if (deliveredNotificationIDs.some((notificationID) => notificationID === mapping.notificationID)) {
          // Cancel the notification if the next notification date is after the end date (relevant for repeating notifications)
          if (mapping.endDate && nextNotificationDate >= mapping.endDate) {
            notificationsToCancel.push({ id: mapping.notificationID });
          }
          return true;
        }

        // Cancel the notification and remove its mapping if the next notification date is after the end date
        if (mapping.endDate && nextNotificationDate >= mapping.endDate) {
          notificationsToCancel.push({ id: mapping.notificationID });
          this.removeNotificationIDInfoFromLocalStorage(mapping.notificationID, deliveredNotificationIDs);
          return false;
        }

        // Cancel all reminder notifications and remove them from LocalStorage
        // This is done to make the code less complex. It is not the most performant solution!
        if (mapping.type === NotificationType.REMINDER) {
          notificationsToCancel.push({ id: mapping.notificationID });
          this.removeNotificationIDInfoFromLocalStorage(mapping.notificationID, deliveredNotificationIDs);
        }

        // Remove all mappings of one-time notifications that lie in the past and cancel the notifications
        if (
          mapping.interval === NotificationInterval.ONCE &&
          mapping.date &&
          currentDate > mapping.date
        ) {
          notificationsToCancel.push({ id: mapping.notificationID });
          this.removeNotificationIDInfoFromLocalStorage(mapping.notificationID, deliveredNotificationIDs);
          return false;
        }

        // Remove all mappings that have no corresponding diary instance and cancel the corresponding notifications
        if (!diaryInstancesWithSchedules.some((diaryInstance) => diaryInstance.id === mapping.idOfDiaryInstance)) {
          this.removeNotificationIDInfoFromLocalStorage(mapping.notificationID, deliveredNotificationIDs);
          this.localStorageService.removeDiaryInstanceCompletedDate(mapping.notificationID);
          notificationsToCancel.push({ id: mapping.notificationID });
          return false;
        }

        return true;
      });

    if (notificationsToCancel.length > 0) {
      await LocalNotifications.cancel({ notifications: notificationsToCancel });
      notificationsToCancel = [];
    }

    // Clean up LocalStorage
    let storedNotificationIDs = this.localStorageService.getAllNotificationIDs();
    storedNotificationIDs
      .filter((notificationID) => !notificationIDMappings.some((mapping) => mapping.notificationID === notificationID))
      .forEach((oldStoredNotificationID) => {
        this.removeNotificationIDInfoFromLocalStorage(oldStoredNotificationID, deliveredNotificationIDs);
      });
    storedNotificationIDs = this.localStorageService.getAllNotificationIDs();

    /*** Schedule normal notifications  ***/
    const schedulesWithOneTimeNotifications: Array<{ schedule: Schedule; diaryInstance: DiaryInstance; }> = [];

    // TODO Refactor
    for (const diaryInstance of diaryInstancesWithSchedules) {
      for (const schedule of diaryInstance.schedules) {
        if (schedule.endDate && currentDate >= schedule.endDate) {
          continue;
        }

        const correspondingNotificationMappings = notificationIDMappings.filter((mapping) => mapping.scheduleID === schedule.scheduleID);

        if (correspondingNotificationMappings.length === 0) {
          // There are no scheduled notifications for this schedule
          if (schedule.startDate && currentDate < schedule.startDate) {
            schedulesWithOneTimeNotifications.push({
              schedule: schedule,
              diaryInstance: diaryInstance
            });
          } else {
            // The schedule has no scheduled periodic notification and no start date / the start date lies in the past
            await this.scheduleNormalNotification(schedule, diaryInstance);
          }
          continue;
        }

        if (
          correspondingNotificationMappings.length === 1 &&
          correspondingNotificationMappings[0].interval !== NotificationInterval.ONCE
        ) {
          // The schedule already has a periodic notification
          continue;
        }

        // The schedule has at least one one-time notification
        // TODO All one-time notifications should have been filtered out above; check if this is still needed here
        schedulesWithOneTimeNotifications.push({
          schedule: schedule,
          diaryInstance: diaryInstance
        });
        // Cancel all one-time notifications so they can be rescheduled.
        // This is done to make the code less complex. It is not the most performant solution!
        for (const mapping of correspondingNotificationMappings) {
          notificationsToCancel.push({ id: mapping.notificationID });
          this.removeNotificationIDInfoFromLocalStorage(mapping.notificationID, deliveredNotificationIDs);
        }
      }
    }

    notificationIDMappings = notificationIDMappings.filter(
      (mapping) => !notificationsToCancel.some((removedNotification) => removedNotification.id === mapping.notificationID));

    if (notificationsToCancel.length > 0) {
      await LocalNotifications.cancel({ notifications: notificationsToCancel });
      notificationsToCancel = [];
    }

    scheduledNotifications = (await LocalNotifications.getPending()).notifications;

    let remainingSchedulesCount = this.MAX_NOTIFICATION_COUNT - scheduledNotifications.length;
    let skip = 0;

    if (remainingSchedulesCount <= 0) {
      return;
    }

    /** Schedule reminder for past notifications if the corresponding diary is not completed and there are reminders in the future **/
    for (const diaryInstance of diaryInstancesWithSchedules) {
      if (remainingSchedulesCount <= 0) {
        break;
      }

      const lastCompletedDate = this.localStorageService.getDiaryInstanceCompletedDate(diaryInstance.id) ?? new Date(0);

      for (const schedule of diaryInstance.schedules) {
        const previousNotificationDate: Date | null = this.getPreviousNotificationDate(schedule);

        if (
          previousNotificationDate === null ||
          previousNotificationDate <= lastCompletedDate
        ) {
          // There is no previous notification or
          // the user completed the diary instance after the previous notification
          continue;
        }

        for (
          let reminderIndex = 0;
          reminderIndex < schedule.remindersAfterMinutes.length && remainingSchedulesCount > 0;
          reminderIndex++
        ) {
          const reminderDate = new Date(previousNotificationDate.getTime());
          reminderDate.setMinutes(reminderDate.getMinutes() + schedule.remindersAfterMinutes[reminderIndex]);

          if (reminderDate <= currentDate) {
            continue;
          }

          await this.scheduleReminderNotification(
            schedule,
            diaryInstance,
            reminderIndex,
            previousNotificationDate
          );

          remainingSchedulesCount -= 1;
        }
      }
    }

    /** Schedule one-time notifications and reminders **/
    let notificationsToSchedule: Array<boolean> = [true];

    while (
      remainingSchedulesCount > 0 &&
      notificationsToSchedule.some((it) => it === true)
    ) {
      notificationsToSchedule = [];

      // Schedule one of every one-time notification
      for (const scheduleTuple of schedulesWithOneTimeNotifications) {
        if (remainingSchedulesCount <= 0) {
          break;
        }

        const notificationDate: Date | null = this.getNextNotificationDate(
          scheduleTuple.schedule,
          false,
          skip
        );

        if (notificationDate === null) {
          notificationsToSchedule.push(false);
          continue;
        }
        notificationsToSchedule.push(true);

        await this.scheduleOneTimeNotification(
          scheduleTuple.schedule,
          scheduleTuple.diaryInstance,
          notificationDate
        );

        remainingSchedulesCount -= 1;
      }

      // Schedule reminders for all future notifications
      for (const diaryInstance of diaryInstancesWithSchedules) {
        for (const schedule of diaryInstance.schedules) {
          const notificationDate: Date | null = this.getNextNotificationDate(
            schedule,
            false,
            skip
          );

          if (notificationDate === null) {
            notificationsToSchedule.push(false);
            continue;
          }

          if (schedule.remindersAfterMinutes.length === 0) {
            notificationsToSchedule.push(false);
            continue;
          }

          notificationsToSchedule.push(true);

          for (
            let reminderIndex = 0;
            reminderIndex < schedule.remindersAfterMinutes.length && remainingSchedulesCount > 0;
            reminderIndex++
          ) {
            await this.scheduleReminderNotification(
              schedule,
              diaryInstance,
              reminderIndex,
              notificationDate
            );

            remainingSchedulesCount -= 1;
          }
        }
      }

      skip += 1;
    }
  }

  /**
   * Schedules a normal notification for a given schedule and diary instance.
   * Only schedules the notification if the current date is later than the start date and the date of the next notification
   * is before the end date.
   *
   * @param schedule The schedule for the notification.
   * @param diaryInstance The diary instance to which the schedule belongs.
   */
  private async scheduleNormalNotification(
    schedule: Schedule,
    diaryInstance: DiaryInstance
  ): Promise<ScheduleResult> {
    const currentDate = new Date();

    if (schedule.startDate && currentDate < schedule.startDate) {
      // Schedule will start in the future
      return;
    }

    const nextNotificationDate: Date | null = this.getNextNotificationDate(schedule, true);

    if (
      schedule.endDate &&
      nextNotificationDate !== null &&
      nextNotificationDate >= schedule.endDate
    ) {
      // Next notification would be sent after end date
      return;
    }

    const newNotification: LocalNotificationSchema = {
      title: this.translateService.instant("NOTIFICATION.NORMAL_TITLE"),
      body: `${this.translateService.instant("NOTIFICATION.BODY")} ${diaryInstance.title}`,
      id: this.localStorageService.getUniqueNotificationID(),
      sound: "",
    };

    switch (schedule.interval) {
      case NotificationInterval.DAILY: {
        newNotification.schedule = {
          on: {
            hour: schedule.timeOfDay.hour,
            minute: schedule.timeOfDay.minute,
          },
        };
        break;
      }
      case NotificationInterval.WEEKLY: {
        if (schedule.dayOfWeek === undefined && schedule.dayOfWeek === null) {
          return;
        }
        newNotification.schedule = {
          on: {
            weekday: DayOfWeek.convertToLocalNotificationWeekday(schedule.dayOfWeek),
            hour: schedule.timeOfDay.hour,
            minute: schedule.timeOfDay.minute,
          },
        };
        break;
      }
      case NotificationInterval.MONTHLY: {
        if (schedule.dayOfMonth === undefined && schedule.dayOfMonth === null) {
          return;
        }
        newNotification.schedule = {
          on: {
            day: schedule.dayOfMonth,
            hour: schedule.timeOfDay.hour,
            minute: schedule.timeOfDay.minute,
          },
        };
        break;
      }
      case NotificationInterval.ONCE: {
        // One-off notifications are not currently supported for normal notifications.
        // They are only used internally to implement the start date and reminder functionality.
        return;
      }
    }

    this.localStorageService.addNotificationIDMapping(newNotification.id, {
      notificationID: newNotification.id,
      type: NotificationType.NORMAL,
      interval: schedule.interval,
      idOfDiaryInstance: diaryInstance.id,
      scheduleID: schedule.scheduleID,
      endDate: schedule.endDate,
    });

    return LocalNotifications.schedule({
      notifications: [newNotification]
    });
  }

  /**
   * Schedules a one-time notification for a given schedule and diary instance at a specific date.
   *
   * @param schedule The schedule for the notification.
   * @param diaryInstance The diary instance to which the schedule belongs.
   * @param notificationDate The date at which the notification will be scheduled.
   * @returns A promise that resolves to the result of the notification scheduling.
   */
  private scheduleOneTimeNotification(
    schedule: Schedule,
    diaryInstance: DiaryInstance,
    notificationDate: Date
  ): Promise<ScheduleResult> {
    if (!notificationDate) {
      return;
    }

    const oneTimeNotification: LocalNotificationSchema = {
      title: this.translateService.instant("NOTIFICATION.NORMAL_TITLE"),
      body: `${this.translateService.instant("NOTIFICATION.BODY")} ${diaryInstance.title}`,
      id: this.localStorageService.getUniqueNotificationID(),
      sound: "",
      schedule: {
        at: notificationDate,
      },
    };

    this.localStorageService.addNotificationIDMapping(oneTimeNotification.id, {
      notificationID: oneTimeNotification.id,
      type: NotificationType.NORMAL,
      interval: NotificationInterval.ONCE,
      idOfDiaryInstance: diaryInstance.id,
      scheduleID: schedule.scheduleID,
      date: notificationDate,
    });

    return LocalNotifications.schedule({
      notifications: [oneTimeNotification],
    });
  }

  /**
   * Schedules a reminder notification for a given schedule, diary instance, and reminder index, and date.
   *
   * @param schedule The schedule for the reminder.
   * @param diaryInstance The diary instance to which the schedule belongs.
   * @param reminderIndex The index of the reminder in the schedule reminders array.
   * @param reminderDate Date of the reminder (only the day, month, and year are relevant).
   * @returns A promise that resolves to the result of the notification scheduling.
   */
  private scheduleReminderNotification(
    schedule: Schedule,
    diaryInstance: DiaryInstance,
    reminderIndex: number,
    reminderDate: Date
  ): Promise<ScheduleResult> {

    reminderDate.setHours(
      schedule.timeOfDay.hour,
      schedule.timeOfDay.minute + schedule.remindersAfterMinutes[reminderIndex],
      0,
      0
    );

    if (schedule.endDate && reminderDate >= schedule.endDate) {
      return Promise.resolve({ notifications: [] })
    }

    const newReminderNotification: LocalNotificationSchema = {
      title: this.translateService.instant("NOTIFICATION.REMINDER_TITLE"),
      body: `${this.translateService.instant("NOTIFICATION.BODY")} ${diaryInstance.title}`,
      id: this.localStorageService.getUniqueNotificationID(),
      sound: "",
      schedule: {
        at: reminderDate,
      },
    };

    this.localStorageService.addNotificationIDMapping(
      newReminderNotification.id,
      {
        notificationID: newReminderNotification.id,
        type: NotificationType.REMINDER,
        interval: NotificationInterval.ONCE,
        idOfDiaryInstance: diaryInstance.id,
        scheduleID: schedule.scheduleID,
        date: reminderDate,
        reminderIndex: reminderIndex,
      }
    );

    return LocalNotifications.schedule({
      notifications: [newReminderNotification],
    });
  }

  /**
   * Retrieves all unlocked diary instances for the current user.
   *
   * @returns A promise that resolves to an array of unlocked diary instances.
   */
  private async getUnlockedDiaryInstances(): Promise<Array<DiaryInstance>> {
    try {
      const diaryInstancesRequest = this.requestProvider.getMyDiaryInstances();
      const diaryInstances = await lastValueFrom(
        diaryInstancesRequest.pipe(
          map((result) => this.parserService?.parseDiaryInstances(result, true)),
          catchError((error) => throwError(() => error))
        )
      );

      return diaryInstances ?? [];
    } catch (error) {
      console.error("Error fetching unlocked diary instances:", error);
      return [];
    }
  }

  /**
   * Calculates the date when the next normal notification of a schedule will be sent.
   *
   * Example:
   * ```
   * Current Date = 25.06.2024 13:00
   * Start Date   = 27.06.2024 18:00
   * Schedule: daily notification at 14:00
   *
   * -> next notification date = 28.06.2024 at 14:00
   * ```
   *
   * @param schedule The schedule from which the next notification date is calculated.
   * @param ignoreEndDate Whether to ignore the schedules end date in the calculation. If the end date is ignored the next notification date is returned even if it is after the end date.
   * @param skip The number of notifications to skip (default is 0).
   * @returns The date of the next notification or null if there is an error in the schedule schema or if the calculated date is after the end date (if the end date is not ignored).
   */
  private getNextNotificationDate(
    schedule: Schedule,
    ignoreEndDate: boolean,
    skip: number = 0
  ): Date | null {
    let currentOrStartDate: Date;
    const currentDate = new Date();
    if (
      schedule.startDate !== undefined &&
      schedule.startDate !== null &&
      currentDate < schedule.startDate
    ) {
      currentOrStartDate = new Date(schedule.startDate.getTime());
    } else {
      currentOrStartDate = currentDate;
    }

    currentOrStartDate.setSeconds(0, 0);

    // Skip 'skip' amounts of notifications dates
    switch (schedule.interval) {
      case NotificationInterval.DAILY: {
        // Add 'skip' days
        currentOrStartDate.setDate(currentOrStartDate.getDate() + skip);
        break;
      }
      case NotificationInterval.WEEKLY: {
        // Add 'skip' weeks
        currentOrStartDate.setDate(currentOrStartDate.getDate() + (7 * skip));
        break;
      }
      case NotificationInterval.MONTHLY: {
        // Add 'skip' months
        currentOrStartDate.setMonth(currentOrStartDate.getMonth() + skip);
        break;
      }
    }

    const isDateHoursSmaller = currentOrStartDate.getHours() < schedule.timeOfDay.hour;
    const isDateHoursEqual = currentOrStartDate.getHours() === schedule.timeOfDay.hour;
    const isDateMinutesSmaller = currentOrStartDate.getMinutes() < schedule.timeOfDay.minute;

    switch (schedule.interval) {
      case NotificationInterval.DAILY: {
        if (isDateHoursSmaller || (isDateHoursEqual && isDateMinutesSmaller)) {
          currentOrStartDate.setHours(
            schedule.timeOfDay.hour,
            schedule.timeOfDay.minute
          );
        } else {
          currentOrStartDate.setDate(currentOrStartDate.getDate() + 1);
          currentOrStartDate.setHours(
            schedule.timeOfDay.hour,
            schedule.timeOfDay.minute
          );
        }
        break;
      }
      case NotificationInterval.WEEKLY: {
        if (schedule.dayOfWeek === undefined || schedule.dayOfWeek === null) {
          return null;
        }
        const isDateWeekdaySmaller = currentOrStartDate.getDay() < schedule.dayOfWeek;
        const isDateWeekdayEqual = currentOrStartDate.getDay() === schedule.dayOfWeek;
        if (
          isDateWeekdaySmaller ||
          (isDateWeekdayEqual && isDateHoursSmaller) ||
          (isDateWeekdayEqual && isDateHoursEqual && isDateMinutesSmaller)
        ) {
          currentOrStartDate.setDate(currentOrStartDate.getDate() + (schedule.dayOfWeek - currentOrStartDate.getDay()));
          currentOrStartDate.setHours(
            schedule.timeOfDay.hour,
            schedule.timeOfDay.minute
          );
        } else {
          currentOrStartDate.setDate(currentOrStartDate.getDate() + 7 - (currentOrStartDate.getDay() - schedule.dayOfWeek));
          currentOrStartDate.setHours(
            schedule.timeOfDay.hour,
            schedule.timeOfDay.minute
          );
        }
        break;
      }
      case NotificationInterval.MONTHLY: {
        if (schedule.dayOfMonth === undefined || schedule.dayOfMonth === null) {
          return null;
        }
        const isDateDayOfMonthSmaller = currentOrStartDate.getDate() < schedule.dayOfMonth;
        const isDateDayOfMonthEqual = currentOrStartDate.getDate() === schedule.dayOfMonth;
        if (
          isDateDayOfMonthSmaller ||
          (isDateDayOfMonthEqual && isDateHoursSmaller) ||
          (isDateDayOfMonthEqual && isDateHoursEqual && isDateMinutesSmaller)
        ) {
          currentOrStartDate.setDate(schedule.dayOfMonth);
          currentOrStartDate.setHours(
            schedule.timeOfDay.hour,
            schedule.timeOfDay.minute
          );
        } else {
          currentOrStartDate.setMonth(
            currentOrStartDate.getMonth() + 1,
            schedule.dayOfMonth
          );
          currentOrStartDate.setHours(
            schedule.timeOfDay.hour,
            schedule.timeOfDay.minute
          );
        }
        break;
      }
    }

    if (!ignoreEndDate && schedule.endDate && currentOrStartDate >= schedule.endDate) {
      return null;
    }

    return currentOrStartDate;
  }

  /**
   * Calculates the date on which the previous notification of a schedule was sent.
   * 
   * @param schedule The schedule from which the previous notification date is calculated.
   * @returns The date of the previous notification or null if there was no previous notification or if there is an error in the schedule schema.
   */
  private getPreviousNotificationDate(schedule: Schedule): Date | null {
    const notificationDate: Date | null = this.getNextNotificationDate(schedule, true);

    if (notificationDate === null) {
      return null;
    }

    switch (schedule.interval) {
      case NotificationInterval.DAILY: {
        notificationDate.setDate(notificationDate.getDate() - 1);
        break;
      }
      case NotificationInterval.WEEKLY: {
        notificationDate.setDate(notificationDate.getDate() - 7);
        break;
      }
      case NotificationInterval.MONTHLY: {
        notificationDate.setMonth(notificationDate.getMonth() - 1);
        break;
      }
    }

    if (
      (schedule.startDate && notificationDate < schedule.startDate) ||
      (schedule.endDate && notificationDate >= schedule.endDate)
    ) {
      return null;
    }

    return notificationDate;
  }

  /**
   * Initializes local notification listener.
   * Checks if notification permissions are granted before adding listeners.
   */
  private async initializeLocalNotificationListeners() {
    if (
      this.listenersAdded ||
      !Capacitor.isNativePlatform() ||
      (await LocalNotifications.checkPermissions()).display !== "granted"
    ) {
      return;
    }

    LocalNotifications.addListener("localNotificationActionPerformed", (action) => this.handleNotificationAction(action));
    this.listenersAdded = true;
  }

  /**
   * Navigates to the relevant diary entry and removes related displayed notifications when a notification is clicked.
   *
   * @param action The performed action on the local notification.
   */
  private async handleNotificationAction(action: ActionPerformed) {
    const { notification } = action;
    // Set actioned notification so that its mapping is not removed
    this.actionedNotificationID = notification.id;
    const diaryInstances = await this.getUnlockedDiaryInstances();

    // Find diary instance and its Schedule that correspond to the notification
    const mapping: NotificationIDMapping | null = this.localStorageService.getNotificationIDMapping(notification.id);

    if (!mapping) {
      try {
        LocalNotifications.cancel({ notifications: [{ id: notification.id }] });
      } catch (error) {
        console.error("Error canceling notification:", error);
      }
      return;
    }

    const diaryInstance: DiaryInstance | undefined = diaryInstances.find(
      (diaryInstance) => diaryInstance.id === mapping.idOfDiaryInstance
    );

    if (!diaryInstance) {
      const deliveredNotificationIDs = (await (this.getDeliveredNotifications())).notifications.map((notification) => notification.id);
      this.removeNotificationIDInfoFromLocalStorage(notification.id, deliveredNotificationIDs);
      LocalNotifications.cancel({
        notifications: [{ id: notification.id }]
      });
      return;
    }

    // Navigate to the related diary entry
    this.zone.run(() => {
      this.router.navigate(
        ["/lesson/", diaryInstance.lessonOfDiary, diaryInstance.id],
        { queryParams: { diary: true } }
      );
    });

    this.actionedNotificationID = null;
    this.removeDisplayedDiaryNotifications(diaryInstance.id);
  }

  /**
   * Cancels all reminders for a completed diary instance and removes all displayed notifications related to the diary instance.
   *
   * @param idOfDiaryInstance The ID of the completed diary instance.
   */
  public async completedDiary(idOfDiaryInstance: number) {
    this.localStorageService.setDiaryInstanceCompletedDate(idOfDiaryInstance, new Date());

    if ((await LocalNotifications.checkPermissions()).display !== "granted") {
      return;
    }

    // Remove all unnecessary reminders by canceling and rescheduling all reminders
    this.scheduleDiaryNotifications();

    this.removeDisplayedDiaryNotifications(idOfDiaryInstance);
  }

  /**
   * Removes all displayed notifications related to a specific diary instance.
   *
   * @param idOfDiaryInstance The ID of the diary instance whose notifications should be removed.
   */
  private async removeDisplayedDiaryNotifications(idOfDiaryInstance: number) {
    const storedNotificationIDs = this.localStorageService.getAllNotificationIDs();
    // IDs of all notifications belonging to the diary instance
    const diaryInstanceNotificationIDs = storedNotificationIDs
      .map((notificationID) => this.localStorageService.getNotificationIDMapping(notificationID))
      .filter((mapping) => mapping !== null && mapping.idOfDiaryInstance === idOfDiaryInstance)
      .map((mapping) => mapping.notificationID);

    const deliveredNotifications = (await this.getDeliveredNotifications()).notifications;

    const notificationsToRemove: Array<DeliveredNotificationSchema> =
      deliveredNotifications.filter((notificationOrGroup) =>
        diaryInstanceNotificationIDs.includes(notificationOrGroup.id)
      );

    LocalNotifications.removeDeliveredNotifications({
      notifications: notificationsToRemove
    });
  }

  /**
   * Retrieves all delivered notifications.
   * 
   * Note: On Android, the `LocalNotifications.getDeliveredNotifications()` method can throw several exceptions related to
   * type mismatches, such as:
   * - `Key android.<SomeKey> expected String but value was a java.lang.Boolean. The default value <null> was returned.`
   * - `java.lang.ClassCastException: java.lang.Boolean cannot be cast to java.lang.String`
   *
   * Despite these exceptions, the method returns the correct value. The exceptions cannot be caught with a try-catch block 
   * and can be safely ignored.
   *
   * @returns A promise that resolves to the delivered notifications.
   */
  private async getDeliveredNotifications(): Promise<DeliveredNotifications> {
    console.log("On Android, exceptions thrown by LocalNotification.getDeliveredNotifications() can be safely ignored.");
    return LocalNotifications.getDeliveredNotifications();
  }

  /**
   * Removes a notification ID's information from local storage, unless it is currently delivered or has been actioned.
   * 
   * @param notificationID The ID of the notification to remove from local storage.
   * @param deliveredNotificationIDs An array of notification IDs that are currently delivered (i.e., in the notification tray).
   */
  private async removeNotificationIDInfoFromLocalStorage(notificationIDToRemove: number, deliveredNotificationIDs: Array<number>) {
    if (
      deliveredNotificationIDs.some((notificationID) => notificationID === notificationIDToRemove) ||
      (this.actionedNotificationID !== null && this.actionedNotificationID === notificationIDToRemove)
    ) {
      return;
    }

    this.localStorageService.removeNotificationIDMapping(notificationIDToRemove);
    this.localStorageService.removeNotificationIDFromAllIDs(notificationIDToRemove);
  }

  /**
   * Cancels all notifications, removes everything related from local storage, and removes all delivered notifications.
   * Only executes if the notification permissions have been granted.
   */
  public async removeAllNotifications() {
    if (
      !Capacitor.isNativePlatform() || 
      (await LocalNotifications.checkPermissions()).display !== "granted"
    ) {
      return;
    }

    const storedNotificationIDs = this.localStorageService.getAllNotificationIDs();
    const notificationsToCancel: Array<LocalNotificationDescriptor> = [];

    for (const notificationID of storedNotificationIDs) {
      notificationsToCancel.push({ id: notificationID });
      this.removeNotificationIDInfoFromLocalStorage(notificationID, []);
    }

    LocalNotifications.removeAllDeliveredNotifications();
    LocalNotifications.cancel({ notifications: notificationsToCancel });
  }
}

import { DayOfWeek, NotificationInterval } from "./elements/Enums";
import { TimeOfDay } from "./TimeOfDay";

export class Schedule {
  scheduleID: number;
  interval: NotificationInterval;
  timeOfDay: TimeOfDay;
  dayOfWeek?: DayOfWeek; // Only for type === weekly
  dayOfMonth?: number; // Only for type === monthly
  expiresInSeconds?: number;
  startDate?: Date;
  endDate?: Date;
  remindersAfterMinutes: number[];

  constructor(
    id: number,
    interval: NotificationInterval,
    timeOfDay: TimeOfDay,
    remindersAfterMinutes: number[],
    dayOfWeek?: DayOfWeek,
    dayOfMonth?: number,
    expiresInSeconds?: number,
    startDate?: Date,
    endDate?: Date
  ) {
    this.scheduleID = id;
    this.interval = interval;
    this. timeOfDay = timeOfDay;
    this.remindersAfterMinutes = remindersAfterMinutes;
    if (dayOfWeek !== undefined) {
      this.dayOfWeek = dayOfWeek;
    }
    if (dayOfMonth !== undefined) {
      this.dayOfMonth = dayOfMonth;
    }
    if (expiresInSeconds !== undefined) {
      this.expiresInSeconds = expiresInSeconds;
    }
    if (startDate !== undefined) {
      this.startDate = startDate;
    }
    if (endDate !== undefined) {
      this.endDate = endDate;
    }
  }
}

import { Message } from "./Message";

type Participant = { id: number; name: string };

export class Thread {
  id: number = 0;
  subject: string = "";
  answersheetId: number = 0;
  assignedInstanceID: number;
  lessonId: number = 0;
  lessonFeedbackRequired: boolean = false;
  read: boolean;
  createdAt: number = 0;
  unreadMessages: number = 0;
  messages: Message[] = [];
  participants: Participant[] = [];
  picture: string = "";
  updatedAt: number = 0;

  constructor(
    id: number,
    subject: string,
    answersheetId: number,
    assignedInstanceID: number,
    lessonId: number,
    lessonFeedbackRequired: boolean,
    read: boolean,
    createdAt: number,
    unreadMessages: number,
    messages: Message[],
    participants: Participant[],
    picture: string,
    updatedAt: number,
  ) {
    this.id = id;
    this.subject = subject;
    this.answersheetId = answersheetId;
    this.assignedInstanceID = assignedInstanceID;
    this.lessonId = lessonId;
    this.lessonFeedbackRequired = lessonFeedbackRequired;
    this.read = read;
    this.createdAt = createdAt;
    this.unreadMessages = unreadMessages;
    this.messages = messages;
    this.participants = participants;
    this.picture = picture;
    this.updatedAt = updatedAt;
  }
}

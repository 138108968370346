import { Component, OnInit } from '@angular/core';
import { ModalController, ViewWillEnter } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SUPPORT_MAIL } from '../../../../environments/environment';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-technical-support',
  templateUrl: './technical-support.component.html',
  styleUrls: ['./technical-support.component.scss'],
})
export class TechnicalSupportComponent implements OnInit {
  public supportMail = SUPPORT_MAIL;

  constructor(
    private modalController: ModalController,
    private translateService: TranslateService,
    private appComponent: AppComponent,
  ) {
  }
  ngOnInit(): void {
    this.appComponent.overwriteDefaultBackButtonListener(() => {
      this.onCloseDialog();
    });
  }

  onCloseDialog() {
    this.modalController.dismiss();
    this.appComponent.applyDefaultBackButtonListener();
  }

  onOpenEmailClient() {
    window.location.href = 'mailto:' + this.supportMail + '?subject=' + this.translateService.instant('SUPPORT.TECHNICAL_SUPPORT');
  }
}

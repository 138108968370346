import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  GuardResult,
  MaybeAsync,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { of } from "rxjs";
import { LocalStorageService } from "../providers/local-storage/local-storage.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard {
  constructor(
    private router: Router,
    private localStorage: LocalStorageService,
  ) { }

  canActivate(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): MaybeAsync<GuardResult> {
    //check if user has valid token
    if (this.localStorage.getToken() !== "") {
      return of(true);
    } else {
      this.router.navigateByUrl("/login", {
        state: { url: state.url },
      });
      return of(false);
    }
  }
}

import { Component, Input, ViewChild } from '@angular/core';
import { Permission } from '../permission-manager/permission-manager.component';
import { Capacitor } from '@capacitor/core';
import { IonModal } from '@ionic/angular';
import { AppComponent } from 'src/app/app.component';

/**
 * Component to display sensor information and manage permissions for the diary.
 */
@Component({
  selector: 'diary-sensor-info',
  templateUrl: './diary-sensor-info.component.html',
  styleUrls: ['./diary-sensor-info.component.scss']
})
export class DiarySensorInfo {
  /**
   * Boolean indicating if the modal is open that includes information about the used sensors of the diary instance.
   */
  modalIsOpen: boolean = false;

  /**
   * Boolean indicating if the location sensor is used within the diary instance.
   */
  locationIsUsed: boolean = false;

  /**
   * Boolean indicating if the location permission is granted on the system.
   */
  locationPermissionGranted: boolean = false;

  /**
   * Boolean indicating if the pedometer sensor is used within the diary instance.
   */
  pedometerIsUsed: boolean = false;

  /**
   * Boolean indicating if the pedometer permission is granted on the system.
   */
  pedometerPermissionGranted: boolean = false;

  /** Reference to the `ion-modal` DOM element. This is used to make the `dismiss() call on the `IonModal` object.*/
  @ViewChild("modal") modal: IonModal;

  /**
   * Initializes the boolean values indicating whether the corresponding sensors are used in a certain diary instance.
   * This function is called whenever the `usedSensors` input property is set.
   *
   * @param usedSensors - An array of `Sensor` objects representing the sensors being used.
   */
  @Input()
  set usedSensors(usedSensors: Sensor[]) {
    this.initSensorBooleans(usedSensors);
  }

  /**
   * Initializes the boolean values indicating whether permission for a certain sensor is granted on the system.
   * This function is called whenever the `grantedPermissions` input property is set.
   *
   * @param grantedPermissions - An array of `Permission` objects representing the permissions that have been granted.
   */
  @Input()
  set grantedPermissions(grantedPermissions: Permission[]) {
    this.initPermissionBooleans(grantedPermissions);
  }

  constructor(
    private appComponent: AppComponent,
  ) { }

  /**
   * Sets the state of the modal dialog (open or closed).
   * 
   * @param isOpen - Boolean indicating if the modal should be open.
   * @param event - Optional event parameter to stop propagation.
   */
  setModalIsOpen(isOpen: boolean, event = null): void {
    event?.stopPropagation();
    this.modalIsOpen = isOpen;

    if (isOpen) {
      this.overwriteAndroidBackClickBehavior();
    } else {
      this.appComponent.applyDefaultBackButtonListener();
    }
  }

  /**
   * Initializes the booleans for the sensors based on the `usedSensors` list.
   */
  private initSensorBooleans(usedSensors: Sensor[]): void {
    usedSensors.forEach(sensor => {
      switch (sensor) {
        case "location": {
          this.locationIsUsed = true;
          break;
        }

        case "pedometer": {
          this.pedometerIsUsed = true;
          break;
        }
      }
    });
  }

  /**
   * Initializes the booleans for the permissions based on the `grantedPermissions` list.
   */
  private initPermissionBooleans(grantedPermissions: Permission[]): void {
    grantedPermissions.forEach(permission => {
      switch (permission) {
        case "location": {
          this.locationPermissionGranted = true;
          break;
        }

        case "pedometer": {
          this.pedometerPermissionGranted = true;
          break;
        }
      }
    });
  }

  /**
   * This function adds a Capacitor-specific `"backButton"` event listener that is fired by Capacitor.
   * By adding a custom `"backButton"` event listener the back button click event is not forwarded to the web view within the app.
   * As a result, back button clicks do not result in a popstate event within the web view and the current page under the sensor information dialog will not be changed.
   * This behaviour is only required on Android since Android devices do have a physical back button and iOS devices don't.
   */
  private async overwriteAndroidBackClickBehavior() {
    if (Capacitor.getPlatform() === "android") {
      this.appComponent.overwriteDefaultBackButtonListener(() => {
        this.modal.dismiss();
      });
    }
  }
}

/**
 * Type definition for the sensors.
 */
export type Sensor = "location" | "pedometer"
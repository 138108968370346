<ng-container *ngIf="downloadUrl && downloadMode === 'IndirectDownload'">
  <div class="menu-button-and-progress">
    <ion-spinner *ngIf="!isNative && isDownloading" name="crescent"></ion-spinner>
    <b *ngIf="!isNative && isDownloading">
      {{"LESSON.MEDIA_DOWNLOAD" | translate}}{{downloadProgress}}%
    </b>
    <div class="menu-button" [ngClass]="{'hover-effect': !isNative}" (click)="menuButtonClicked()"
      *ngIf="!isNative && !isDownloading || isNative">
      <ion-icon [name]="menuIcon"></ion-icon>
    </div>
    <div class="menu-button hover-effect" *ngIf="!isNative && isDownloading" (click)="abortWebDownload()">
      <ion-icon name="close-outline"></ion-icon>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="downloadUrl && downloadMode === 'DirectDownload'">
  <div class="hyperlink-content">
    <ng-container *ngIf="isNative">
      <fa-icon [icon]="faIcon" (click)="anchorClicked()"></fa-icon>
      <div class="anchor-line" (click)="anchorClicked()">
        <ion-icon name="open-outline"></ion-icon>
        <a>{{clearFileName}}</a>
      </div>
    </ng-container>
    <div *ngIf="!isNative" class="anchor-line">
      <a [href]="downloadUrl" target="_blank" rel="noopener noreferrer" [attr.download]="clearFileName">
        <fa-icon [icon]="faIcon"></fa-icon>
        {{clearFileName}}
      </a>
    </div>
  </div>
</ng-container>

<ion-action-sheet *ngIf="downloadUrl && isNative" [isOpen]="menuIsOpen" [buttons]="menuOptions"
  (didDismiss)="handleSelectedMenuOption($event)">
</ion-action-sheet>

<ion-loading *ngIf="downloadUrl && isNative" [isOpen]="isDownloading"
  [message]="('LESSON.MEDIA_DOWNLOAD' | translate) + downloadProgress + '%'">
</ion-loading>

<permission-onboarding *ngIf="isNative" #permissionOnboardingRef>
</permission-onboarding>
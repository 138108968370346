export class Intervention {
  id: number;
  title: string;
  description: string;
  type: string;
  gamification: Gamification;
  unlockMultiple: boolean;
  unlockType: UnlockType;

  constructor(
    id: number,
    title: string,
    description: string,
    type: string,
    gamification: Gamification,
    unlockMultiple: boolean,
    unlockType: UnlockType,
  ) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.type = type;
    this.gamification = gamification;
    this.unlockMultiple = unlockMultiple;
    this.unlockType = unlockType;
  }
}

export type UnlockType = "manual" | "random";

export interface Gamification {
  progressbars_enabled: boolean;
  skills_order: number[];
}

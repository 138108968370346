import { Injectable } from "@angular/core";
import { RequestProviderService } from "../request-provider/request-provider.service";
import { LocalStorageService } from "../local-storage/local-storage.service";
import { ParserService } from "../parser/parser.service";
import { TokenInterceptorService } from "../token-interceptor/token-interceptor.service";
import { LocalNotificationService } from "src/app/providers/local-notification/local-notification.service";
import { AlertService } from "../alert/alert.service";
import { NavController } from "@ionic/angular";
import { HttpErrorResponse } from "@angular/common/http";
import { UnlockableLesson } from "src/app/pages/lesson/lesson.page";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
  providedIn: "root",
})
export class RequestHelperService {
  constructor(
    private requestProvider: RequestProviderService,
    private localStorage: LocalStorageService,
    private parserService: ParserService,
    private tokenInterceptorService: TokenInterceptorService,
    private localNotificationService: LocalNotificationService,
    private alertService: AlertService,
    private navCtrl: NavController,
    private translateService: TranslateService,
  ) { }

  removeDeviceTokenIdAndLogout() {
    const id = this.localStorage.getDeviceTokenId();
    if (id !== -1) {
      this.requestProvider.deleteDeviceToken(id).subscribe({
        next: (_response) => {
          this.localStorage.removeDeviceTokenId();
          this.logout();
        },
        error: (_error) => {
          this.localStorage.removeDeviceTokenId();
          this.logout();
        },
      });
    } else {
      this.logout();
    }
  }

  logout() {
    this.requestProvider.logout().subscribe({
      next: (_response) => {
        this.tokenInterceptorService.cancelPendingRequests();
        this.localStorage.removeToken();
        this.localStorage.removeUserEmail();
        this.localStorage.removeUserId();
        this.localNotificationService.removeAllNotifications();
        this.parserService.newMessages = 0;
        this.parserService.newFeedback = 0;
        this.parserService.showAnnouncements = false;
        this.parserService.selectedAnnouncement = 0;
        this.navCtrl.navigateRoot("/login", {
          animated: true,
          animationDirection: "forward",
        }); //use this to reset root so that no data of previously logged in user is still there todorouting
        // this.router.navigate(['/login'], {replaceUrl: true});
      },
      error: (error) => {
        if (
          JSON.stringify(error) == '"no internet connection."' ||
          (error instanceof HttpErrorResponse && error.status == 401)
        ) {
          this.localStorage.removeToken();
          this.localStorage.removeUserEmail();
          this.localStorage.removeUserId();
          this.localNotificationService.removeAllNotifications();
          this.navCtrl.navigateRoot("/login", {
            animated: true,
            animationDirection: "forward",
          }); //use this to reset root so that no data of previously logged in user is still there todorouting
        } else {
          this.alertService.showError(
            "ERROR.ERROR",
            "ERROR.ERROR_SOMETHING_WENT_WRONG",
          );
        }
      },
    });
  }

  async unlockQuestionnaires(
    usersChoice: UnlockableLesson | UnlockableLesson[],
    lessonId: number,
    callback?: () => void,
  ): Promise<void> {
    // group the ones with same instance id to reduce requests
    const lessonsOfInstance = new Map<number, number[]>();
    if (!Array.isArray(usersChoice)) {
      //unlock multiple false
      lessonsOfInstance.set(usersChoice.instance.id, [usersChoice.unlock.id]);
    } else {
      //unlock multiple true
      for (const choice of usersChoice) {
        const instanceId = choice.instance.id;
        let lessons = lessonsOfInstance.get(instanceId);
        if (lessons === undefined) {
          lessons = [];
          lessonsOfInstance.set(instanceId, lessons);
        }
        lessons.push(choice.unlock.id);
      }
    }

    //has to be sequentially with await, so that routing to lesson overview AFTER all finished (else data not reloaded AFTER new questionnaires unlocked)
    for (const [instanceId, lessonIds] of lessonsOfInstance) {
      try {
        await this.requestProvider.unlockConditionalQuestionnaire(
          instanceId,
          lessonId,
          lessonIds,
        );
        this.alertService.presentToast("LESSON.UNLOCK_SUCCESSFULLY");
        if (callback !== undefined) {
          callback();
        }
      } catch (error) {
        //todo check error handling (for example no connection)
        let errorDetails = "";
        if (error.status == 403 && error.error.errors[0].code == "1510") {
          errorDetails = this.translateService.instant(
            "ERROR.ERROR_LESSON_NOT_FINISHED",
          );
        } else {
          if (error.error != undefined) {
            if (error.error.errors[0] != undefined) {
              if (error.error.errors[0].details != undefined) {
                errorDetails = error.error.errors[0].details;
              } else {
                errorDetails = error.error.errors[0];
              }
            } else {
              errorDetails = error.error;
            }
          } else {
            errorDetails = error.message;
          }
        }
        this.alertService.showErrorWithDetails(
          "ERROR.ERROR",
          "ERROR.ERROR_UNLOCK_CONDITIONAL",
          errorDetails,
        );
        // TODO If 403 Forbidden gets return the body contains all the questionnaires that couldnt get unlocked
        // Retry the request excluding these questionnaires
        // We send those requests only for questionnaires that arent unlocked already
        // Though this can happen, if the ecoach or sb. from another device unlocks the questionnaire
      }
    }
  }
}

<ion-buttons style="justify-content: flex-end">
  <ion-button id="closeButton" (click)="onCloseDialog()">
    <ion-icon name="close-circle-outline" size="large"></ion-icon>
  </ion-button>
</ion-buttons>
<!-- TODO check status and decide which content to show -->
<!-- jedes dialogData.dailyTaskDone && könnte glaub weggelassen werden -->
<div class="dialog-content">
  <h3>
    {{ "BUDDY.SUCCESS" | translate }}
  </h3>
  @if (dialogData.dailyTaskDone) {
    @if (dialogData.dailyTaskDoneBuddy) {
      <!-- Beide buddies haben den task erledigt (remind timestamps egal)-->
      <p>{{ "BUDDY.BOTH_DONE" | translate }}</p>
    } @else if (!dialogData.reminded) {
      <!-- Ich hab noch nicht erinnert und Buddy hat den task heut nicht erledigt (& nicht reminded aber egal glaub)-->
      <p>{{ "BUDDY.REMIND_TEXT" | translate }}</p>
    } @else {
      <!-- Ich hab schon erinnert und Buddy hat den task heut nicht erledigt (& nicht reminded aber egal glaub)-->
      @if (justReminded) {
        <!-- buddy was reminded right now -->
        <p>{{ "BUDDY.REMINDED" | translate }}</p>
      } @else {
        <!-- was on earlier open -->
        <p>{{ "BUDDY.ALREADY_REMINDED" | translate }}</p>
      }
    }
  }
</div>

<div class="dialog-action">
  <!-- TODO dont show sometimes, but the dialog-action div should stay -->
  @if (
    dialogData.dailyTaskDone &&
    !dialogData.reminded &&
    !dialogData.dailyTaskDoneBuddy
  ) {
    <ion-button
      id="remindBuddyButton"
      class="dialog-action"
      color="primary"
      fill="solid"
      [disabled]="disableButton"
      (click)="sendReminderMail()"
    >
      {{ "BUDDY.REMIND" | translate }}
    </ion-button>
  }
</div>
<canvas class="celebrate" #confettiCanvasRef></canvas>

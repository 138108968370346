import { Injectable } from '@angular/core';
import { SensingService as SensingServicePlugin, isSensingServiceError } from '@aas2/aas2-sensing-service';
import { SensoryInformation, PedometerInformation, LocationInformation } from 'src/app/models/SensoryInformation';
import { PermissionManager } from 'src/app/components/permission-manager/permission-manager.component';
import { Capacitor } from '@capacitor/core';
import { LocalStorageService } from "../local-storage/local-storage.service";

/**
 * This service is responsible for collection location and pedometer data of the `@aas2/aas2-sensing-service` plugin.
 * Please use the `retrieveSensoryData` for this purpose.
 */
@Injectable({
  providedIn: 'root'
})
export class SensingService {

  constructor(
    private permissionManager: PermissionManager,
    private localStorageService: LocalStorageService
  ) { }

  /**
   * Retrieves sensory data including pedometer and location information if required.
   * 
   * @param pedometerRequired - Indicates if pedometer data is required.
   * @param locationRequired - Indicates if location data is required.
   * @returns A promise that resolves to an instance of SensoryInformation or null if not on a native platform.
   */
  public async retrieveSensoryData(pedometerRequired: boolean, locationRequired: boolean): Promise<SensoryInformation | null> {
    if (!Capacitor.isNativePlatform()) {
      return null;
    }

    const sensingServiceVersion = "0.4.1";
    const pedometer = pedometerRequired ? this.retrievePedometerData() : null;
    const location = locationRequired ? this.retrieveLocationData() : null;
    const resolvedSensoryInformation = await Promise.all([pedometer, location]);

    return new SensoryInformation(sensingServiceVersion, resolvedSensoryInformation[0], resolvedSensoryInformation[1]);
  }

  /**
   * Retrieves pedometer data from the sensing service plugin.
   * 
   * @returns A promise that resolves to an instance of `PedometerInformation`.
   */
  private async retrievePedometerData(): Promise<PedometerInformation> {
    try {
      const pedometerInitialized = this.localStorageService.getPedometerInitialized();

      // sets the recorded steps to 0 at the first pedometer initialization.
      if (!pedometerInitialized) {
        this.localStorageService.setPedometerInitialized(true);
        await SensingServicePlugin.getCurrentStepCount({
          since: "initialization",
          resetInit: true
        });
      }

      const pedometerSensorInfo = await SensingServicePlugin.getCurrentStepCount({
        since: "initialization"
      });

      const status = "success";
      const errorMessage = null;
      const collectedAt = pedometerSensorInfo.timestamp;
      const steps = pedometerSensorInfo.stepCount;

      return new PedometerInformation(status, errorMessage, collectedAt, steps);

    } catch (error) {
      if (isSensingServiceError(error)) {
        return new PedometerInformation(error.code, error.message, null, null);
      } else {
        const status = "unspecified-error";
        const errorMessage = `No sensing service specific error with name ${error.name} was thrown. Error message: ${error.message}`;

        return new PedometerInformation(status, errorMessage, null, null);
      }
    }
  }

  /**
   * Retrieves location data from the sensing service plugin.
   * 
   * @returns A promise that resolves to an instance of `LocationInformation`.
   */
  private async retrieveLocationData(): Promise<LocationInformation> {
    try {
      const locationSensorInfo = await SensingServicePlugin.getCurrentLocation({anonymize : true});

      const status = "success";
      const errorMessage = null;
      const permission = await this.permissionManager.getLocationPermissionAccuracy();
      const collectedAt = locationSensorInfo?.timestamp ?? null;
      const accuracy = locationSensorInfo?.accuracy ?? null;
      const latitude = locationSensorInfo?.latitude ?? null;
      const longitude = locationSensorInfo?.longitude ?? null;
      const altitude = locationSensorInfo?.altitude ?? null;
      const altitudeAccuracy = locationSensorInfo?.altitudeAccuracy ?? null;
      const speed = locationSensorInfo?.speed ?? null;

      return new LocationInformation(status, errorMessage, permission, collectedAt, accuracy, latitude, longitude, altitude, altitudeAccuracy, speed);
    } catch (error) {
      if (isSensingServiceError(error)) {
        return new LocationInformation(error.code, error.message, null, null, null, null, null, null, null, null);
      } else {
        const status = "unspecified-error";
        const errorMessage = `No sensing service specific error with name ${error.name} was thrown. Error message: ${error.message}`;

        return new LocationInformation(status, errorMessage, null, null, null, null, null, null, null, null);
      }
    }
  }
}

import {Injectable, EventEmitter} from '@angular/core';
import {Platform} from "@ionic/angular";
import {FCM} from "@awesome-cordova-plugins/fcm/ngx";
// import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import {Router} from "@angular/router";
import {map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
// import {Invitation} from "../../models/Invitation";
import {LocalStorageService} from "../local-storage/local-storage.service";
import {RequestProviderService} from "../request-provider/request-provider.service";
import {AlertService} from "../alert/alert.service";
import {ParserService} from "../parser/parser.service";

@Injectable({
    providedIn: 'root'
})
export class FirebaseService {
    updateEmitter: EventEmitter<Event> = new EventEmitter<Event>();

    constructor(
      private platform: Platform,
      private router: Router,
      private fcm: FCM,
      // private afMessaging: AngularFireMessaging,
      private localStorage: LocalStorageService,
      private alertService: AlertService,
      private parserService: ParserService,
      private requestProvider: RequestProviderService,
    ) {
    }

    /**
     * @method getToken (mobile)
     *
     * Gets the necessary firebase token of the device and registers it as device token to the sever
     */
    public getToken() {
      this.fcm.getToken().then(token => {
        this.requestProvider.registerDeviceToken(token).pipe(map((result) => result), catchError(error => {return throwError(error)}))
          .subscribe((response) => {
            let tokenId = response.headers.get('Location');
            tokenId = tokenId.split("").reverse().join("");
            tokenId = tokenId.substr(0, tokenId.indexOf('/'));
            tokenId = tokenId.split("").reverse().join("");
            this.localStorage.setDeviceTokenId(tokenId);
          }, (error) => {
            this.alertService.showError('ERROR.ERROR', 'ERROR.ERROR_FIREBASE_TOKEN');
          });
      });
    }

    /**
     * @method listenMobile
     *
     * Starts the listener for receiving message of firebase on mobile devices
     */
    public listenMobile() {
      this.fcm.onNotification().subscribe(payload => {
        this.showAlertMessage("You got new data", "New data", "threads/chat-overview", "Go to conversations");
      });
    }

    // mobile
    public showAlertMessage(msg: string, header: string, route: string, text: string) {
      if (this.router.url.includes("lesson") || this.router.url.includes("chat")) {
        setTimeout(() => this.showAlertMessage(msg, header, route, text), 5000);
        return;
      }
      this.alertService.showChoice(header, msg, 'BUTTONS.CANCEL', text,
        () => {},
        () => {
          this.router.navigate([route]);
      });
    }

    /**
     * @method onTokenRefresh (mobile)
     *
     * Subscribe to the event of changes to the firebase token and registers the new token to the serve
     */
    public onTokenRefresh() {
      this.fcm.onTokenRefresh()
        .subscribe((token) => {
          this.requestProvider.registerDeviceToken(token).pipe(map((result) => result), catchError(error => {return throwError(error)}))
          .subscribe((response) => {
            let tokenId = response.headers.get('Location');
            tokenId = tokenId.split("").reverse().join("");
            tokenId = tokenId.substr(0, tokenId.indexOf('/'));
            tokenId = tokenId.split("").reverse().join("");
            this.localStorage.setDeviceTokenId(tokenId);
          }, (error) => {
            this.alertService.showError('ERROR.ERROR', 'ERROR.ERROR_FIREBASE_TOKEN');
          });
      });
    }

    /**
     * @method requestPermission (web)
     * Requests permission to allow push notification for Web Users. If allowed, it genereates a token which gets registered to the server
     */
    public requestPermission() {
      // this.afMessaging.requestToken
      //   .subscribe((token) => {
      //     this.requestProvider.registerDeviceToken(token).pipe(map((result) => result), catchError(error => {return throwError(error)}))
      //     .subscribe((response) => {
      //       let tokenId = response.headers.get('Location');
      //       tokenId = tokenId.split("").reverse().join("");
      //       tokenId = tokenId.substr(0, tokenId.indexOf('/'));
      //       tokenId = tokenId.split("").reverse().join("");
      //       this.localStorage.setDeviceTokenId(tokenId);
      //     }, (error) => {
      //       this.alertService.showError('ERROR.ERROR', 'ERROR.ERROR_FIREBASE_TOKEN');
      //     });
      //   },
      //   (error) => {});
    }

    /**
     * @method listenWeb
     *
     * Starts the listener for receiving message of firebase for Web Users
     */
    public listenWeb() { //todoRefactoring reload data on current page
      // this.afMessaging.messages.subscribe((payload) => {
      //   let body = JSON.parse(payload['notification']['body'])
      //   console.log(payload['notification'])
      //   if(body.answersheet_id != null || body.title == 'intervention_instance_state_updated: feedback_provided'){
      //     this.alertService.presentToast("CHAT_FEEDBACK.NEW_FEEDBACK");
      //     this.updateEmitter.emit();

      //     //show number of new feedback threads in navigation
      //     this.requestProvider.getMyFeedback().pipe(map((result) => result), catchError(error => {return throwError(error)}))
      //       .subscribe((response) => {
      //         this.parserService.parseThreads(response, 'feedback');
      //       },
      //       (error) => {
      //         this.alertService.showError('ERROR.ERROR', 'ERROR.ERROR_DATA_LOADING');
      //       });
      //   }else if(body.thread_id != null){
      //     this.alertService.presentToast("CHAT_FEEDBACK.NEW_MESSAGE");
      //     this.updateEmitter.emit();

      //     //show number of new messages in navigation
      //     this.requestProvider.getMyMessages().pipe(map((result) => result), catchError(error => {return throwError(error)}))
      //       .subscribe((response) => {
      //         this.parserService.parseThreads(response, 'messages');
      //       }, (error) => {
      //         this.alertService.showError('ERROR.ERROR', 'ERROR.ERROR_DATA_LOADING');
      //       });
      //   }else{
      //     //todoRefactoring active state?
      //     switch (body.title) {
      //       case 'intervention_instance_created':
      //         this.alertService.presentToast("CHAT_FEEDBACK.NEW_INSTANCE");
      //         this.updateEmitter.emit();
      //         break;
      //       case 'intervention_instance_state_updated: paused':
      //         this.alertService.presentToast("CHAT_FEEDBACK.INSTANCE_PAUSED");
      //         this.updateEmitter.emit();
      //         break;
      //       case 'intervention_instance_state_updated: canceled':
      //         this.alertService.presentToast("CHAT_FEEDBACK.INSTANCE_CANCELED");
      //         this.updateEmitter.emit();
      //         break;
      //       case 'intervention_instance_state_updated: completed':
      //         this.alertService.presentToast("CHAT_FEEDBACK.INSTANCE_COMPLETED");
      //         this.updateEmitter.emit();
      //         break;
      //       case 'intervention_instance_unlocked_questionnaires_updated':
      //         this.alertService.presentToast("CHAT_FEEDBACK.LESSON_UNLOCKED");
      //         this.updateEmitter.emit();
      //         break;
      //       case 'study_invitation_sent':
      //         this.alertService.presentToast("CHAT_FEEDBACK.STUDY_INVITATION_AVAILABLE");
      //         this.parserService.invitations.push(new Invitation(-1, -1, "", ""));
      //         break;
      //       default:
      //         this.updateEmitter.emit(); //todoRefactoring remove
      //         break;
      //     }
      //   }
      // });
    }
}
